/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Appointment,
  DomainResource,
  Encounter,
  HumanName,
  Identifier,
  Location,
  Patient,
  Practitioner,
  PractitionerRole,
  Schedule,
  Slot,
} from 'fhir/r4'
import {
  AgeType,
  LabResult,
  InvoicePanel,
  LmsOrder,
  LmsPanel,
  PatientVisit,
  SequencePanel,
  SequenceTest,
  TestResultLimit,
  normal,
  panelObject,
  ModifiedTestPanel,
  testObject,
  ResultCount,
  DashboardTab,
  numericOutcome,
} from './models'
import { LabOrder, LabTest, OrderEventTest, Panel } from '../labTests/models'
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import {
  DMS_CLIENT_ID,
  DMS_CLIENT_SECRET,
  DMS_PASSWORD,
  DMS_USERNAME,
  LABS_PROJECT_ID,
  MEDUNITED_AWGMENT_GATEWAY,
  NEW_LINE_CHARACTER,
  NEW_LINE_REPLACE,
  OPD_PROJECT_ID,
  downloadUrl,
  notificationUrl,
} from '../../utils/constants'
import KeycloakService from '../../utils/keycloakService'
import { getDoctorName } from '../scheduler/schedulerSlice'
import { startSxpProxy } from '../../utils/api'
import { getTotalAge } from '../../utils/dateUtils'
import { emptyString, intent } from '../Radiology/constants'
import { allocation } from '../bedManagement/modals'
import { toast } from 'react-toastify'
import ToastMessage from './components/ToastMessage'
import { useAppSelector } from '../../app/hooks'
import { selectedLmsPatientPhone } from './lmsSlice'
import { FormattedPatient } from '../patients/Patients'
import { PatientData } from '../patients/patientsSlice'
import { capitalizeFirstLetter } from '../patients/utils'

export const matchPatientName = (
  searchParam: string,
  name?: HumanName
): boolean => {
  if (!searchParam) {
    return true
  }
  const splitNames = searchParam.split(' ')
  const mpn = (param: string) =>
    (name?.family?.toLowerCase?.().startsWith(param.toLowerCase()) ||
      name?.given?.some((n) =>
        n.toLowerCase?.().startsWith(param.toLowerCase())
      )) ??
    false
  return splitNames.some((sn) => mpn(sn))
}

export const matchPatientUhid = (
  searchParam: string,
  identifier?: Identifier[]
): boolean => {
  if (!searchParam) {
    return true
  }
  return (
    !!identifier?.[2]?.value
      ?.toLowerCase()
      ?.startsWith(searchParam.toLowerCase()) ||
    !!identifier?.[0]?.value
      ?.toLowerCase()
      ?.startsWith(searchParam.toLowerCase())
  )
}

export const matchName = (name: string, searchParam: string): boolean => {
  return name
    ?.split?.(' ')
    .some((n) => n.toLowerCase().startsWith(searchParam.toLowerCase()))
}

export const makeName = (names?: HumanName[]): string => {
  const name = names?.[0]
  const given = name?.given?.join(' ') ?? ''
  const family = name?.family ?? ''
  return (given + ' ' + family).trim()
}

export const getLabTestCount = (order: LmsOrder | LabOrder) => {
  const panelCount =
    order.panels?.reduce((acc, cur) => acc + cur.lab_tests.length, 0) ?? 0
  return panelCount + (order.lab_tests?.length ?? 0)
}

export const getSearchResults = (data: any, searchParam: string) => {
  let filtered = data
  if (searchParam) {
    filtered = data?.filter((d: any) =>
      d.name?.toLowerCase().includes(searchParam.toLowerCase())
    )
  }
  filtered?.sort((a: any, b: any) => (a.id < b.id ? -1 : 1))
  return filtered
}

export const getWardResults = (
  data: any,
  searchParam: string,
  locationSearch?: string
) => {
  if (!data || data.length === 0) {
    return []
  }

  let filtered = data
  if (searchParam || locationSearch) {
    filtered = data?.filter((ward: any) => {
      const nameMatches =
        !searchParam ||
        ward.ward_name?.toLowerCase().includes(searchParam.toLowerCase())
      const locationMatches =
        !locationSearch || ward.location_id === locationSearch

      return nameMatches && locationMatches
    })
  }

  filtered.sort((a: any, b: any) => (a.id < b.id ? -1 : 1))
  return filtered
}
export const getRoomResults = (
  data: any,
  searchParam: string,
  locationSearch?: string
) => {
  if (!data || data.length === 0) {
    return []
  }
  let filtered = data
  if (searchParam || locationSearch) {
    filtered = data?.filter((room: any) => {
      const nameMatches =
        !searchParam ||
        room.room_name?.toLowerCase().includes(searchParam.toLowerCase())
      const locationMatches =
        !locationSearch || room.ward_detail.location_id === locationSearch
      return nameMatches && locationMatches
    })
  }

  filtered.sort((a: any, b: any) => (a.id < b.id ? -1 : 1))
  return filtered
}
export const getBedResults = (
  data: any,
  searchParam: string,
  locationSearch?: string
) => {
  if (!data || data.length === 0) {
    return []
  }
  let filtered = data
  if (searchParam || locationSearch) {
    filtered = data?.filter((bed: any) => {
      const nameMatches =
        !searchParam ||
        bed.bed_number?.toLowerCase().includes(searchParam.toLowerCase())
      const locationMatches =
        !locationSearch || bed.location_id === locationSearch
      return nameMatches && locationMatches
    })
  }

  filtered.sort((a: any, b: any) => (a.id < b.id ? -1 : 1))
  return filtered
}
export const getWards = (data: any, locationSearch?: string) => {
  if (!data || data.length === allocation.zeroNumber) {
    return []
  }
  let filtered = data
  if (locationSearch) {
    filtered = data?.filter((bed: any) => {
      const locationMatches = !locationSearch || bed.location === locationSearch
      return locationMatches
    })
  }
  return filtered
}
export const getListView = (data: any, locationSearch?: string) => {
  if (!data || data.length === allocation.zeroNumber) {
    return []
  }
  let filtered = data
  if (locationSearch) {
    filtered = data?.filter((bed: any) => {
      const locationMatches = !locationSearch || bed.location === locationSearch
      return locationMatches
    })
  }
  return filtered
}

export const groupTests = (
  saved: LabOrder,
  selectedPanels: Panel[],
  selectedLabTests: LabTest[]
) => {
  const filteredSavedPanels =
    saved?.panels?.filter(
      (sp) => selectedPanels.findIndex((p) => p.id === sp.id) === -1
    ) ?? []
  const fPanels =
    selectedPanels.filter(
      (sp) =>
        !saved?.panels || saved?.panels?.findIndex((p) => p.id === sp.id) === -1
    ) ?? []
  const filteredSavedTests =
    saved?.lab_tests?.filter(
      (slt) =>
        selectedPanels
          .flatMap((sp) => sp.lab_tests)
          .findIndex((p) => p.id === slt.id) === -1 &&
        selectedLabTests.findIndex((lt) => lt.id === slt.id) === -1
    ) ?? []
  const fTests =
    selectedLabTests.filter(
      (slt) =>
        !saved?.lab_tests ||
        saved?.lab_tests?.findIndex((lt) => lt.id === slt.id) === -1
    ) ?? []
  return {
    filteredPanels: [...filteredSavedPanels, ...fPanels],
    filteredLabTests: [...filteredSavedTests, ...fTests],
  }
}

const sortBySequence = (items: SequenceTest[], seq: number[]) => {
  const seqObj: Record<string, number> = seq.reduce(
    (a, c, i) => ({ ...a, [`${c}`]: i + 1 }),
    {}
  )
  items.sort((a, b) => {
    const aIdx = seqObj[a.id]
    const bIdx = seqObj[b.id]
    if (!aIdx && !bIdx) {
      return a.id < b.id ? -1 : 1
    }
    if (!aIdx) {
      return 1
    }
    if (!bIdx) {
      return -1
    }
    return aIdx - bIdx
  })
  return items
}

export const sortResultsBySequence = <T extends { lab_test_id: number }>(
  items: T[],
  seq: number[]
): T[] => {
  const seqObj: Record<string, number> = seq.reduce(
    (a, c, i) => ({ ...a, [`${c}`]: i + 1 }),
    {}
  )
  items.sort((a, b) => {
    const aIdx = seqObj[a.lab_test_id]
    const bIdx = seqObj[b.lab_test_id]
    if (!aIdx && !bIdx) {
      return a.lab_test_id < b.lab_test_id ? -1 : 1
    }
    if (!aIdx) {
      return 1
    }
    if (!bIdx) {
      return -1
    }
    return aIdx - bIdx
  })
  return items
}

export const orderTestsBySequence = (panel?: SequencePanel): SequenceTest[] => {
  if (panel) {
    const seq = panel.lab_test_sequence?.sequence
    const tests = panel.lab_tests
    if (!seq) {
      return tests
    }
    return sortBySequence(tests.slice(), seq)
  }
  return []
}
export const formatInvoiceData = (
  data: any,
  status: string,
  labDepartments?: any
): InvoicePanel[] => {
  const approvedTests = data.filter((dt: any) => {
    return (
      dt.lab_results?.[0]?.test_status === 'APPROVED' || status === 'COMPLETED'
    )
  })
  const normalizedTests = approvedTests.map((dt: any) => {
    const sampleName = dt.panel?.sample?.name
    const resultType = dt?.test_result_type?.result_type
    const result = dt.lab_results?.[0]
    const limit = result?.test_result_limit
    const range = []
    if (resultType === numericOutcome) {
      if (limit?.ref_range) {
        range.push(limit.ref_range)
      }
    } else {
      if (limit?.min_value || limit?.min_value === 0) {
        range.push(limit.min_value)
      }
      if (limit?.max_value) {
        range.push(limit.max_value)
      }
    }
    const pathologyName = result?.authorised_by_name
    const pathologySignature = labDepartments?.find(
      (e: any) => e.id === result.authorised_by_id
    )?.signature
    const pathologyDepartment = labDepartments?.find(
      (e: any) => e.id === result.authorised_by_id
    )?.code
    return {
      id: dt.id,
      name: dt.name,
      enteredName: result?.referred_out ? '' : result?.result_entered_by,
      value: result?.value ?? '',
      sample: sampleName,
      enteredOn: result?.result_entered_on ?? '',
      referred: result?.referred_to ?? '',
      organization: result?.organization,
      unit: limit?.test_unit_type?.unit_type ?? '',
      range:
        resultType === numericOutcome ? range?.[0]?.split(',') : range ?? '',
      rangeValue:
        resultType === numericOutcome
          ? checkRange(range, result?.value)
          : range
          ? range
          : '',
      defeciency: '',
      abnormal: limit?.abnormal ?? '',
      method: limit?.test_method?.method_name ?? '',
      department: dt.department,
      panel: dt.panel,
      resultType: dt?.test_result_type?.result_type,
      extraValue: result?.extra_value ?? '',
      values: result?.values,
      interpretation: dt.panel.interpretation ?? '',
      interpretationImage: dt.panel.interpretation_image ?? '',
      summaryReason: result?.summary_reason
        ? result?.summary_reason.replace(/\\\\/g, '\\')
        : '',
      pathologyName: pathologyName ?? '',
      pathologySignature: pathologySignature ?? '',
      pathologyDepartment: pathologyDepartment ?? '',
    }
  })
  const groupedPanels = normalizedTests.reduce((acc: any, cur: any) => {
    if (acc[cur.panel.id]) {
      acc[cur.panel.id].push(cur)
    } else {
      acc[cur.panel.id] = [cur]
    }
    return acc
  }, {})
  const panels: any = []
  for (const pKey in groupedPanels) {
    const pArr = groupedPanels[pKey]
    const pnl = pArr[0]
    const sequence = pnl?.panel?.lab_test_sequence?.sequence
    const tests = pArr.map((pa: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { panel, ...rest } = pa
      return rest
    })
    if (sequence?.length) {
      sortBySequence(tests, sequence)
    }
    const obj = {
      id: pKey,
      name: pnl?.panel?.name,
      sequence: sequence,
      tests: tests,
      sample: pnl.sample,
      department: pnl.department,
      resultType: pnl.resultType,
      interpretation: pnl?.panel?.interpretation,
      interpretationImage: pnl?.panel?.interpretation_image,
      pathologyName: pnl?.pathologyName,
      pathologySignature: pnl?.pathologySignature,
    }
    panels.push(obj)
  }
  return panels
}

export const getApplicableRange = (
  limits: TestResultLimit[],
  patient: Patient | null
): TestResultLimit | undefined => {
  const genderFiltered = limits.filter(
    (limit) => !limit.gender || limit.gender === patient?.gender
  )
  const current = new Date()
  const dob = new Date(patient?.birthDate ?? '')
  const ageFiltered = genderFiltered.filter((limit) => {
    const ageType: AgeType | undefined = limit.age_type
    const age =
      !ageType || ageType === 'y'
        ? differenceInCalendarYears(current, dob)
        : ageType === 'm'
        ? differenceInCalendarMonths(current, dob)
        : differenceInCalendarDays(current, dob)
    if (!limit.min_age && !limit.max_age) {
      return limit
    }
    if (limit.min_age && !limit.max_age) {
      return age >= limit.min_age
    }
    if (!limit.min_age && limit.max_age) {
      return age <= limit.max_age
    }
    return age >= limit.min_age && age <= limit.max_age
  })
  return ageFiltered[0]
}

export const getFormattedNumber = (num: string | undefined | null): string => {
  if (!num) {
    return ''
  }
  if (isNaN(+num)) {
    return num
  }
  const val = parseFloat(num)
  if (val < 1000) {
    return num
  }
  if (val >= 100000) {
    const rem = Math.floor((val % 100000) / 1000)
    const quo = Math.floor(val / 100000)
    return `${quo}.${rem > 9 ? rem : '0' + rem} Lakhs`
  }
  return val.toLocaleString('en-IN')
}

export const isOutsideRange = (
  value: string,
  range: [number | undefined, number | undefined]
): boolean => {
  if (!value) {
    return false
  }
  if (isNaN(+value)) {
    return false
  }
  const parsed = parseFloat(value)
  return !!(range[0] && range[0] > parsed) || !!(range[1] && range[1] < parsed)
}

export const isOutsideNormalRange = (
  value: string,
  range?: TestResultLimit
): boolean => {
  if (range && (range?.min_value || range?.max_value) && value) {
    const parsed = parseFloat(value)
    return (
      !isNaN(parsed) &&
      ((!!range?.min_value && parsed < range?.min_value) ||
        (!!range?.max_value && parsed > range?.max_value))
    )
  }
  return false
}

export const encodeNewLineText = (text?: string | null): string | undefined => {
  if (text) {
    return text.replaceAll(NEW_LINE_CHARACTER, NEW_LINE_REPLACE)
  }
}

export const decodeNewLineText = (text?: string | null): string => {
  if (!text) {
    return ''
  }
  return text.replaceAll(NEW_LINE_REPLACE, NEW_LINE_CHARACTER)
}
export const summaryReason = (summary: string) => {
  let count = 0
  const removeLine = summary.replace(/\\n+/g, '\n').replace(/\n+/g, () => {
    count++
    return `\n${count}. `
  })
  return removeLine
}

export const groupResultsToTests = (
  results: LabResult[]
): Record<number, LabResult[]> => {
  const grouped = results
    .filter((r) => r.test_status !== 'REJECTED')
    .reduce((acc: Record<number, LabResult[]>, cur) => {
      const panelId = cur.lab_test.panel.id
      if (acc[panelId]) {
        acc[panelId].push(cur)
      } else {
        acc[panelId] = [cur]
      }
      return acc
    }, {})
  for (const key in grouped) {
    const unsorted = grouped[key]
    const seq = unsorted[0]?.lab_test?.panel?.lab_test_sequence?.sequence ?? []
    grouped[key] = sortResultsBySequence(unsorted, seq)
  }
  return grouped
}

export const prepareOrderEventTests = (
  panels: Panel[],
  tests: LabTest[],
  testStatus: 'ORDERED' | 'ORDER_MODIFIED' = 'ORDERED'
): OrderEventTest[] => {
  const current = new Date().toISOString()
  const user = KeycloakService.getUsername()
  const pTests: OrderEventTest[] = panels
    .flatMap((p) => p.lab_tests)
    .map((p) => ({
      test_id: p.id,
      status: testStatus,
      updatedOn: current,
      updatedBy: user,
    }))
  const lTests: OrderEventTest[] = tests.map((p) => ({
    test_id: p.id,
    status: testStatus,
    updatedOn: current,
    updatedBy: user,
  }))
  return [...pTests, ...lTests]
}

export const getSelectedTestSet = (
  panels: LmsPanel[],
  checkAll: boolean,
  checked: number[],
  isCollecting = false
): Set<number> => {
  const selectedTestIds = panels
    .filter(
      (p) =>
        (!isCollecting || (isCollecting && !p.checked)) &&
        ((checkAll && !checked.includes(p.id)) ||
          (!checkAll && checked.includes(p.id)))
    )
    .flatMap((p) => p.lab_tests.map((plt) => plt.id))
  return new Set(selectedTestIds)
}

export const isAbnormal = (range: string, value: string) =>
  range
    .split(',')
    .map((str) => str.trim().toLowerCase())
    .includes(value.trim().toLowerCase())

export const formatPatientVisits = (
  entry: DomainResource[]
): PatientVisit[] => {
  const entryObj: Record<string, DomainResource> = entry.reduce(
    (acc, cur) => ({ ...acc, [cur.id ?? '']: cur }),
    {}
  )
  const visits: Encounter[] = entry.filter(
    (e) => e.resourceType === 'Encounter'
  ) as Encounter[]
  return visits.map((v) => {
    const id = v.id ?? ''
    const appointmentId = v.appointment?.[0]?.reference?.split('/')?.[1] ?? ''
    const appointment = entryObj[appointmentId] as Appointment
    const fallbackDate = appointment?.start ?? ''
    const slotId = appointment?.slot?.[0]?.reference?.split('/')?.[1] ?? ''
    const slot = entryObj[slotId] as Slot
    const date = slot?.start
    const scheduleId = slot?.schedule?.reference?.split('/')?.[1] ?? ''
    const schedule = entryObj[scheduleId] as Schedule
    const locationId =
      schedule?.actor
        ?.find((sra: any) => sra.reference.startsWith('Location'))
        ?.reference?.split('/')?.[1] ?? ''
    const location = entryObj[locationId] as Location
    const doctorId =
      schedule?.actor
        ?.find((sra: any) => sra.reference.startsWith('Practitioner'))
        ?.reference?.split('/')?.[1] ?? ''
    const doctor = entryObj[doctorId] as Practitioner
    return {
      id: id,
      date: date ?? fallbackDate,
      doctor: getDoctorName(doctor) ?? '',
      specialty: appointment?.specialty?.[0]?.coding?.[0]?.display ?? '',
      source: location?.name ?? '',
    }
  })
}

export const mapPatients = async (nodes: LmsOrder[]): Promise<LmsOrder[]> => {
  try {
    const nodeIds = nodes.map((n) => n.patient_id)
    const ids = Array.from(new Set(nodeIds)).join(',')
    const response = await startSxpProxy(
      OPD_PROJECT_ID,
      intent.getPatientsByIdsApi,
      { ids }
    )
    const entry = response.data.entry
    if (entry?.length) {
      const patientObj = entry.reduce(
        (acc: any, cur: any) => ({ ...acc, [cur.resource.id]: cur }),
        {}
      )
      return nodes.map((node) => {
        const pat = node.patient_id ? patientObj[node.patient_id] : null
        const thopId = pat?.resource?.identifier?.[0]?.value ?? 'ThopId'
        const gender = pat?.resource?.gender
        const assignedGender =
          gender === 'male' ? 'M' : gender === 'female' ? 'F' : 'O'
        return {
          ...node,
          patientThopId: thopId,
          patientUhid: pat?.resource?.identifier?.[2]?.value ?? thopId,
          patientName: makeName(pat?.resource?.name),
          patientGender: assignedGender,
          patientMobile: pat?.resource?.telecom?.find(
            (prt: any) => prt.use === 'mobile'
          )?.value,
          patientAge: getTotalAge(
            new Date(pat?.resource?.birthDate ?? new Date())
          ),
        }
      })
    }
    return nodes
  } catch (er) {
    return nodes
  }
}
export const mappedPatients = async (nodes: any): Promise<any> => {
  try {
    const nodeIds = nodes?.map((n: any) => n?.patient_registered_id)
    const ids = Array.from(new Set(nodeIds)).join(',')
    const response = await startSxpProxy(
      OPD_PROJECT_ID,
      intent.getPatientsByIdsApi,
      { ids }
    )
    const entry = response?.data?.entry
    if (entry?.length) {
      const patientObj = entry?.reduce(
        (acc: any, cur: any) => ({ ...acc, [cur.resource.id]: cur }),
        {}
      )
      return nodes.map((node: any) => {
        const pat = node?.patient_registered_id
          ? patientObj[node?.patient_registered_id]
          : null
        const gender = pat?.resource?.gender
        const assignedGender =
          gender === 'male' ? 'M' : gender === 'female' ? 'F' : 'O'
        return {
          patientName: makeName(pat?.resource?.name),
          patientGender: assignedGender,
          patientMobile: pat?.resource?.telecom?.find(
            (prt: any) => prt?.use === 'mobile'
          )?.value,
          patientAge: getTotalAge(
            new Date(pat?.resource?.birthDate ?? new Date())
          ),
          id: node?.id,
          bedId: node?.bed_id,
          bedStatus: node?.bed_status,
          patientId: node?.patient_id,
          dateCreated: node?.date_created,
          dateStarted: node?.date_started,
          dateOfDischarge: node?.date_of_discharge,
          bedNumber: node?.bed.bed_number,
          location: node?.bed.location_id,
          patient_registered_id: node?.patient_registered_id,
        }
      })
    }
    return nodes
  } catch (er) {
    return nodes
  }
}
export const removeDuplicates = (arr: any) => {
  const filteredArray = arr.map((e: any) => e?.lab_test?.panel?.name)
  return [...new Set(filteredArray)]
}

export const mapResults = async (
  orderIds: (string | undefined)[]
): Promise<Record<string, ResultCount>> => {
  try {
    const resp = await startSxpProxy(LABS_PROJECT_ID, 'getLabResultsByOrders', {
      orders: orderIds,
    })
    const resultObj: LabResult[] = resp.data.lab_result

    const reducedObj: Record<number, LabResult[]> = {}
    resultObj.forEach((rs) => {
      if (reducedObj[rs.lab_order_id]) {
        reducedObj[rs.lab_order_id].push(rs)
      } else {
        reducedObj[rs.lab_order_id] = [rs]
      }
    })
    const out: Record<number, ResultCount> = {}
    for (const obj in reducedObj) {
      const res = reducedObj[obj]
      const totalLength = removeDuplicates(res)?.length
      const rejectedArray = res.filter((r) => r.test_status === 'REJECTED')
      const rejectedLength = removeDuplicates(rejectedArray)?.length
      const total = totalLength - rejectedLength
      const referred = removeDuplicates(
        res.filter((r) => r.referred_out && r.test_status !== 'REJECTED')
      ).length
      const pending = removeDuplicates(
        res.filter(
          (r) => r.test_status === 'COLLECTED' || r.test_status === 'ENTERED'
        )
      ).length
      out[obj] = {
        total,
        referred,
        pending,
      }
    }
    return out
  } catch (er) {
    return {}
  }
}

export const filterOrders = (
  orders: LmsOrder[],
  searchParam: string
): LmsOrder[] => {
  if (!searchParam) return orders
  const splitNames = searchParam.split(' ')
  return orders.filter((item) => {
    return (
      item.patientThopId?.startsWith(searchParam) ||
      item.patientUhid?.toLowerCase().startsWith(searchParam.toLowerCase()) ||
      (item.lr_number && ('' + item.lr_number)?.startsWith?.(searchParam)) ||
      splitNames.some((sn) => matchName(item.patientName, sn)) ||
      item.source?.toLowerCase().startsWith(searchParam.toLowerCase())
    )
  })
}

export const splitOrders = (
  orders: LmsOrder[],
  date: string,
  activeTab: DashboardTab
) => {
  let statuses: string[]
  switch (activeTab) {
    case 'collection':
      statuses = [
        'ORDERED',
        'ORDER_MODIFIED',
        'COLLECTED',
        'COLLECTION_REJECTED',
        'REJECTED',
      ]
      break
    case 'testing':
      statuses = ['COLLECTION_APPROVED', 'ENTERED', 'ENTERED_REFERRED']
      break
    case 'validation':
      statuses = ['SUBMITTED']
      break
    default:
      statuses = []
  }
  const current: LmsOrder[] = []
  const other: LmsOrder[] = []
  for (const order of orders) {
    const tests = order.order_events?.[0]?.tests ?? []
    const filtered = tests
      .filter((t) => statuses.includes(t.status))
      .map((t) => t.updatedOn.slice(0, 10))
    if (!filtered.length) {
      continue
    }
    if (filtered.some((f) => f === date)) {
      current.push(order)
    } else {
      other.push(order)
    }
  }
  return [current, other]
}
export const getLabOrdersByLocation = (
  data: LmsOrder[],
  locationSearch?: string
) => {
  if (!data || data.length === allocation.zeroNumber) {
    return []
  }
  let filtered = data
  if (locationSearch) {
    filtered = data?.filter((order: LmsOrder) => {
      const locationMatches = !locationSearch || order.source === locationSearch
      return locationMatches
    })
  }
  return filtered
}
export function exportToCsv(headers: string[], data: any[], filename: string) {
  const csvContent = [
    headers.join(','),
    ...data.map((item) => Object.values(item).join(',')),
  ].join('\n')
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const blobURL = URL.createObjectURL(blob)

  const anchor = document.createElement('a')
  anchor.href = blobURL
  anchor.setAttribute('download', filename)
  anchor.click()

  setTimeout(() => {
    URL.revokeObjectURL(blobURL)
  }, 100)
}
export const formatDoctors = (
  roles: { resource: PractitionerRole }[],
  id?: string
) => {
  if (!id) {
    return 'NA'
  }
  const resType = roles.find(
    (e) => e.resource?.practitioner?.reference?.split?.('/')?.[1] === id
  )
  const code =
    resType?.resource?.specialty?.[0]?.coding?.[0]?.code ?? emptyString
  const display =
    resType?.resource?.specialty?.[0]?.coding?.[0]?.display ?? emptyString
  return display
}
export const checkRange = (inputStr: any, value: string) => {
  const ranges = inputStr?.[0]?.split(',').map((r: any) => r.trim().split(':'))
  if (!ranges) {
    return ''
  } else {
    const ranges = inputStr?.[0]
      ?.split(',')
      .map((r: any) => r.trim().split(':'))
    for (let i = 0; i < ranges.length; i++) {
      const [label, limits] = ranges[i]
      const parts = limits.split('-').map((l: any) => l.trim())
      if (parts.length === 1) {
        const bound = parseFloat(parts[0].replace(/[<=>]/g, ''))
        if (
          (parts[0].startsWith('<') && parseInt(value) < bound) ||
          (parts[0].startsWith('=') && parseInt(value) === bound) ||
          (parts[0].startsWith('>') && parseInt(value) > bound)
        ) {
          return label
        }
      } else {
        const lower = parseFloat(parts[0])
        const upper = parseFloat(parts[1])
        if (parseInt(value) >= lower && parseInt(value) <= upper) {
          return label
        }
      }
    }
    return 'Not In Range'
  }
}

export const downloadModifiedPdf = (
  modifiedPdfBytes: Uint8Array | null,
  pdfFileName: string
) => {
  if (modifiedPdfBytes) {
    const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download =
      pdfFileName?.substring(0, pdfFileName?.length - 4) + '_modified.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }
}

export const getWhatsappStatus = (
  whatsappResponseID: string,
  patientPhone: string,
  name: string,
  fileId: string
) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('react-token')}`,
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
  }
  fetch(
    `${MEDUNITED_AWGMENT_GATEWAY}/notification/v1/getMessageStatus/` +
      whatsappResponseID,
    requestOptions
  )
    .then((response) => response.json())
    .then((data: any) => {
      // Success response will be either DELIVERED or READ
      if (data?.data === 'DELIVERED' || data?.data === 'READ') {
        toast(<ToastMessage message='Whatsapp message sent successfully' />, {
          position: 'bottom-center',
          hideProgressBar: true,
          closeButton: false,
          theme: 'dark',
          autoClose: 2000,
        })
      } else {
        toast(<ToastMessage message='Failed to send whatsapp message' />, {
          position: 'bottom-center',
          hideProgressBar: true,
          closeButton: false,
          theme: 'dark',
          autoClose: 2000,
        })
        sendSMS(true, patientPhone, name, fileId)
      }
    })
    .catch((error) => {
      console.error(error)
      toast(
        <ToastMessage message='Failed to send whatsapp message. Network Error.' />,
        {
          position: 'bottom-center',
          hideProgressBar: true,
          closeButton: false,
          theme: 'dark',
          autoClose: 2000,
        }
      )
    })
}

export function sendSMS(
  result: boolean,
  number: string | undefined,
  name: string,
  fileId: string
) {
  // Shorten the download URL
  const urlShortnerRequestOptions: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('dms-token')}`,
      'Content-Type': 'text/plain',
    },
    body: downloadUrl + fileId,
    redirect: 'follow',
  }

  fetch(`${MEDUNITED_AWGMENT_GATEWAY}/shrt/shorten`, urlShortnerRequestOptions)
    .then((response) => {
      if (response.status === 500) {
        toast(<ToastMessage message='Unable to send SMS.' />, {
          position: 'bottom-center',
          hideProgressBar: true,
          closeButton: false,
          theme: 'dark',
          autoClose: 2000,
        })
        throw new Error('Server error. Please try again.')
      }
      return response.text()
    })
    .then((result) => {
      const downloadUrl = result
      return downloadUrl
    })
    .then((downloadUrl) => {
      // Send SMS Api
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

      const payload = {
        operationName: 'SEND_SMS',
        variables: {
          mailMessage: {
            templateId: '1107171922956594834',
            to: 91 + (number ?? ''),
            from: '7989560838',
            body: encodeURI(
              'Dear ' +
                name +
                ', your reports are ready. View it here: ' +
                downloadUrl +
                '\nCall us 9851247365\nMedunited Hospitals'
            ),
          },
        },
        query:
          'mutation SEND_SMS($mailMessage: smsMessage!) {\n  sendSMS(input: $mailMessage)\n}\n',
      }
      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('react-token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        redirect: 'follow',
      }

      fetch(notificationUrl, requestOptions)
        .then((response) => response.json())
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((_) => {
          if (result) {
            toast(<ToastMessage message='SMS sent successfully' />, {
              position: 'bottom-center',
              hideProgressBar: true,
              closeButton: false,
              theme: 'dark',
              autoClose: 2000,
            })
          } else {
            toast(
              <ToastMessage message='Lab Results Rejection SMS sent successfully' />,
              {
                position: 'bottom-center',
                hideProgressBar: true,
                closeButton: false,
                theme: 'dark',
                autoClose: 2000,
              }
            )
          }
        })
        .catch((error) => {
          toast(<ToastMessage message='Failed to send SMS. Network Error.' />, {
            position: 'bottom-center',
            hideProgressBar: true,
            closeButton: false,
            theme: 'dark',
            autoClose: 2000,
          })
          console.error(error)
        })
    })
    .catch((error) => console.error(error))
}
