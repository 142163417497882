import { useState } from 'react'
import BodyComponent, { PartsInput } from '../../../components/BodyComponent'
import './IpdInvasive.scss'

export default function IpdInvasive() {
  const [params, setParams] = useState<any>({
    head: { selected: false },
    neck: { selected: false },
    leftShoulder: { selected: false },
    rightShoulder: { selected: false },
    leftArm: { show: true },
    rightArm: { selected: false },
    chest: { selected: false },
    stomach: { selected: false },
    leftLeg: { selected: false },
    rightLeg: { selected: false },
    rightHand: { selected: false },
    leftHand: { selected: false },
    leftFoot: { selected: false },
    rightFoot: { selected: false },
  })

  const [parts, setParts] = useState<any>()
  const [bodyModel, setBodyModel] = useState<string>()
  const onChange = (parts: PartsInput) => setParts(parts)
  const onClick = (id: string) => console.log('Changed Id:', id)

  return (
    <div className='human-component-container'>
      <div className='human-body-container'>
        {params && (
          <BodyComponent
            partsInput={params}
            bodyModel={bodyModel}
            onChange={onChange}
            onClick={onClick}
          />
        )}
      </div>
      <div className='human-body-selected-conatiner'>
        <div>Selected Regions</div>
        <div>{JSON.stringify(parts)}</div>
      </div>
    </div>
  )
}
