import React, { useRef, useEffect, useState } from 'react'
import { AdminTableProps } from '../../models'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsAdminSearch,
  setLmsFormMode,
  setLmsItemId,
} from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import LinkButton from './LinkButton'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'
import CreateUpdatePanelForm from './CreateUpdatePanelForm'

const PanelTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const dispatch = useAppDispatch()
  const filtered = getSearchResults(data, searchParam)
  const textLength = 30
  const [more, setMore] = useState(false)
  const [moreIndex, setMoreIndex] = useState(-1)
  const [selectedPanelData, setSelectedPanelData] = useState(null)

  filtered?.sort?.((a: any, b: any) => {
    if (a.department?.department_name < b.department?.department_name) {
      return -1
    } else if (a.department?.department_name > b.department?.department_name) {
      return 1
    } else {
      return a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1
    }
  })

  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = tableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const toggleView = (more: boolean, index: number) => {
    setMore(!more)
    setMoreIndex(index === moreIndex ? -1 : index)
  }

  const renderInterpretation = (interpretation: any, index: number) => {
    const text = interpretation.slice(0, textLength)
    if (interpretation.length <= textLength || moreIndex === index) {
      return interpretation
    }

    return <>{text}</>
  }
  const handleEditClick = (id: any) => {
    startSxpProxy(LABS_PROJECT_ID, 'getPanelById', { panelId: id })
      .then((data) => {
        setSelectedPanelData(data?.data?.panel_by_pk)
        dispatch(setLmsFormMode('edit'))
        dispatch(setLmsItemId(id))
      })
      .catch((error) => {
        console.error('Failed to fetch panel details:', error)
      })
  }
  return (
    <div>
      {selectedPanelData ? (
        <CreateUpdatePanelForm />
      ) : (
        <div
          className='data-table table-fixed admin-table-table-container'
          ref={tableContainerRef}
          style={{ overflowY: 'auto' }}
        >
          <table className='data-table table-fixed admin-table relative'>
            <thead style={{ position: 'sticky', top: '0px' }}>
              <tr>
                <th className='table-w-5'>Id</th>
                <th>Test Name</th>
                <th className='table-w-10'>Department</th>
                <th className='table-w-10'>Sample</th>
                <th className='table-w-10'>Handled At</th>
                <th className='table-w-5'>TAT</th>
                <th style={{ wordWrap: 'break-word' }}>Interpretation</th>
              </tr>
            </thead>
            <tbody>
              {filtered?.length ? (
                filtered.map((d: any, index: any) => (
                  <tr key={d.id}>
                    <td>{d.id}</td>
                    <td>
                      <LinkButton
                        id={d?.id}
                        name={d?.name}
                        onClick={() => handleEditClick(d?.id)}
                      />
                    </td>
                    <td>{d?.department?.department_name ?? '-'}</td>
                    <td>{d?.sample.name}</td>
                    <td>{d?.handled_at ?? '-'}</td>
                    <td>-</td>
                    <td style={{ wordWrap: 'break-word' }}>
                      {d?.interpretation ? (
                        <div>
                          {renderInterpretation(d?.interpretation, index)}
                          <span
                            style={{ color: 'blue', cursor: 'pointer' }}
                            onClick={() => toggleView(more, index)}
                          >
                            {index === moreIndex
                              ? '...View Less'
                              : '...View More'}
                          </span>
                        </div>
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className='text-center'>
                    No Panels Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default PanelTable
