import React, { useEffect, useRef, useState } from 'react'
import './user-management-form.css'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { setQueryParam } from '../../membership/membershipSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PractitionerOption } from './UserForm'
import KeycloakRolesDialog from './KeyclockRoles'
import { selectLocations } from '../../location/locationSlice'

interface UserData {
  identifier: any
  id: string
  username: string
  firstName: string
  lastName: string
  email?: string
  enabled: boolean
  createdTimestamp: any
}

const UserData = () => {
  const [usersData, setUsersData] = useState<UserData[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [searchText, setSearchText] = useState('')
  const [operatingUnit, setOperatingUnit] = useState('')
  const [showOperatingUnit, setShowOperatingUnit] = useState(false)
  const [filterStatus, setFilterStatus] = useState<
    'all' | 'active' | 'inactive'
  >('all')
  const dispatch = useAppDispatch()
  const locations = useAppSelector(selectLocations)
  const [showFullId, setShowFullId] = useState(false)
  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  const [rolesopen, setRolesOpen] = useState(false)
  const [odooUser, setOdooUser] = useState<any[]>([])
  const [fhirUser, setFhirUser] = useState<any[]>([])
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [keycloakRoles, setKeycloakRoles] = useState<PractitionerOption[]>([])
  const navigate = useNavigate()
  useEffect(() => {
    fetchUsers()
    getodooUsers()
    getfhirUsers()
  }, [])

  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container?.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window?.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window?.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  const fetchUsers = () => {
    startSxpProxy(ADMIN_PROJECT_ID, 'KeyclockUsers', {})
      ?.then((response) => {
        const data = response?.data?.data
        setUsersData(data)
      })
      .catch((err) => console.error('Error fetching users:', err))
  }
  const getodooUsers = () => {
    startSxpProxy(ADMIN_PROJECT_ID, 'getOdooUsers', {})
      ?.then((response) => {
        const data = response?.data
        setOdooUser(data)
      })
      .catch((err) => console.error('Error fetching users:', err))
  }

  const getfhirUsers = () => {
    startSxpProxy(ADMIN_PROJECT_ID, 'getfhirUsers')
      ?.then((response) => {
        const data = response?.data?.entry
        setFhirUser(data)
      })
      .catch((err) => console.error('Error fetching users:', err))
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setSelectedUser(null)
  }

  const handlePasswordReset = () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match')
      return
    }

    startSxpProxy(ADMIN_PROJECT_ID, 'passwordReset', {
      userId: selectedUser?.id,
      value: newPassword,
    })
      ?.then((data) => {
        console.log('Password reset successful', data)
      })
      .catch((err) => console.error('Error resetting password:', err))

    setNewPassword('')
    setConfirmPassword('')
    setIsModalOpen(false)
    setSelectedUser(null)
  }

  const handleUserSearch = () => {
    startSxpProxy(ADMIN_PROJECT_ID, 'keyclockUserSearch', { value: searchText })
      ?.then((response) => {
        const data = response?.data?.data
        setUsersData(data)
      })
      .catch((err) => console.error('Error fetching search results:', err))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleUserSearch()
    }
  }

  const handleCreateUser = () => {
    navigate('/administration/userCreation')
  }

  const handleClick = () => {
    setShowFullId(!showFullId)
  }

  const handleCloseDialog = () => {
    setRolesOpen(false)
  }

  const handleKeycloakRoleClick = (userId: any) => {
    setSelectedUserId(userId)
    startSxpProxy(ADMIN_PROJECT_ID, 'keyclockCompositeRoles', { userId })
      ?.then((response) => {
        if (response?.data) {
          setKeycloakRoles(
            Array?.isArray(response?.data?.data) ? response?.data?.data : []
          )
          setRolesOpen(true)
        }
      })
      .catch((err) => console.error('Error fetching Keycloak roles:', err))
  }
  // needed
  const handleGetAssginedLocation = (keyclockId: any) => {
    setSelectedUserId(keyclockId)
    startSxpProxy(ADMIN_PROJECT_ID, 'UserAssignedLocation', { keyclockId })
      ?.then((response) => {
        const operatingUnitId = response?.data?.data?.attributes?.location?.[0]
        if (operatingUnitId) {
          const matchingLocation = locations?.find(
            (location) => location?.resource.id === operatingUnitId
          )
          if (matchingLocation && matchingLocation?.resource.name) {
            setOperatingUnit(matchingLocation.resource.name)
          } else {
            setOperatingUnit('No matching location name found')
          }
        } else {
          setOperatingUnit('Amalapuram')
        }
        setShowOperatingUnit(true)
      })
      .catch((err) => console.error('Error fetching Keycloak roles:', err))
  }

  const filteredUsers = usersData?.filter((user) => {
    switch (filterStatus) {
      case 'active':
        return user.enabled === true
      case 'inactive':
        return user.enabled === false
      default:
        return true
    }
  })

  const handleChangeStatus = (
    userId: string,
    username: string,
    active: boolean
  ) => {
    startSxpProxy(ADMIN_PROJECT_ID, 'inactiveUser', {
      userId,
      username,
      active,
    })
      ?.then((response) => {
        fetchUsers()
      })
      ?.catch((err) => console.error('Error updating user status:', err))
  }
  const isUserChecked = (userId: string) => {
    return odooUser?.some((ou) => ou?.oauth_uid === userId)
  }

  const isCheckboxChecked = (userId: any) => {
    return fhirUser?.some(
      (id) => id?.resource?.identifier?.[1]?.value === userId
    )
  }

  const renderStatusSelect = (user: UserData) => {
    return (
      <select
        style={{ fontSize: '10px' }}
        className='medication-input w75'
        value={user.enabled ? 'active' : 'inactive'}
        onChange={(e) =>
          handleChangeStatus(
            user?.id,
            user?.username,
            e.target.value === 'active'
          )
        }
      >
        <option value='active'>Active</option>
        <option value='inactive'>Inactive</option>
      </select>
    )
  }

  function convertTimestamp(timestamp: any) {
    const date = new Date(timestamp)
    return date?.toLocaleString()
  }
  return (
    <div>
      <div className='test-search'>
        <div className='membershipText' style={{ marginBottom: '0rem' }}>
          Users
        </div>
        <div className='patient-search'>
          <div>
            <select
              className='category-filter'
              value={filterStatus}
              onChange={(e) =>
                setFilterStatus(e.target.value as 'all' | 'active' | 'inactive')
              }
              style={{
                borderRadius: '4px',
                backgroundColor: 'white',
              }}
            >
              <option value='all'>All</option>
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>
          <input
            title='Search by name can be partial. Please enter full text to search by Phone or Id'
            placeholder='Search by Id, Name, Phone'
            type='search'
            value={searchText}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className='search-input'
          />
          <button
            className='btn btn-primaryBtn ml10 '
            onClick={handleCreateUser}
          >
            Create
          </button>
        </div>
      </div>
      <div className='appointments-table-container' ref={VisitTableRef}>
        <table className='data-table admin-table'>
          <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
            <tr>
              <th className='table-w-4'>Sl&nbsp;No</th>
              <th className='table-w-10'>UserName</th>
              <th className='table-w-20'>Keyclock Id</th>
              <th className='table-w-10'>Name</th>
              <th className='table-w-15'>Email.Id</th>
              <th className='text-left table-w-2'>Roles</th>
              {/* <th className='text-left table-w-2'>Location</th> */}
              {/* <th className='text-left table-w-2'>Edit</th> */}
              <th className='text-left table-w-2'>Reset Pwd</th>
              <th>Created On</th>
              <th>THOP</th>
              <th>Odoo</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {filteredUsers?.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td className='text-left'>{user?.username}</td>
                <td
                  onClick={handleClick}
                  style={{ cursor: 'pointer', fontSize: '11px' }}
                >
                  {showFullId ? user.id : `${user.id.substring(0, 15)}...`}
                </td>
                <td className='text-left'>
                  {user.firstName} {user?.lastName}
                </td>
                <td className='text-left'>{user?.email || 'N/A'}</td>
                <td>
                  <a
                    className='view-visit-button'
                    onClick={() => handleKeycloakRoleClick(user?.id)}
                  >
                    View
                  </a>
                </td>
                {/* <td>
                  <a
                    className='view-visit-button'
                    onClick={() => handleGetAssginedLocation(user?.id)}
                  >
                    View
                  </a>
                </td> */}

                {/* <td className='text-left'>
                  <a
                    className='view-visit-button'
                    onClick={() => handleEditClick(user)}
                  >
                    Edit
                  </a>
                </td> */}
                <td className='text-left'>
                  <a
                    className='view-visit-button'
                    onClick={() => {
                      setSelectedUser(user)
                      setIsModalOpen(true)
                    }}
                  >
                    Reset
                  </a>
                </td>
                <td style={{ fontSize: '8px' }}>
                  {convertTimestamp(user?.createdTimestamp)}
                </td>
                <td>
                  <input
                    type='checkbox'
                    name='THOP'
                    checked={isCheckboxChecked(user?.id)}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type='checkbox'
                    name='odoo'
                    checked={isUserChecked(user.id)}
                    readOnly
                  />
                </td>

                <td style={{ fontSize: '10px' }}>{renderStatusSelect(user)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Dialog
        open={showOperatingUnit}
        onClose={() => setShowOperatingUnit(false)}
      >
        <DialogTitle>Operating Unit</DialogTitle>
        <DialogContent>{operatingUnit}</DialogContent>
      </Dialog>

      {selectedUser && (
        <Dialog open={isModalOpen} onClose={handleModalClose}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                handlePasswordReset()
              }}
            >
              <TextField
                label='New Password'
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                margin='normal'
              />
              <TextField
                label='Confirm Password'
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                margin='normal'
              />
              <Button type='submit'>Submit</Button>
              <Button onClick={handleModalClose}>Cancel</Button>
            </form>
          </DialogContent>
        </Dialog>
      )}
      <KeycloakRolesDialog
        isOpen={rolesopen}
        onClose={handleCloseDialog}
        roles={keycloakRoles}
        userId={selectedUserId}
      />
    </div>
  )
}

export default UserData
