import React, { ChangeEvent, useState } from 'react'
import { useSamples } from '../useSamples'
import { useSequencePanels } from '../useSequencePanels'
import SequenceTable from './SequenceTable'
import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage'
import { toastOptions } from '../../models'

const SequencesConfig = () => {
  const [reload, setReload] = useState(1)
  const samples = useSamples()
  const [sample, setSample] = useState<number>(-1)
  const panels = useSequencePanels(sample, reload)
  const [panel, setPanel] = useState<number>(-1)

  const handleSampleChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const parsed = parseInt(ev.target.value)
    if (!isNaN(parsed)) {
      setSample(parsed)
      setPanel(-1)
    }
  }

  const handlePanelChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const parsed = parseInt(ev.target.value)
    if (!isNaN(parsed)) {
      setPanel(parsed)
    }
  }

  const handleReorder = () => {
    toast(<ToastMessage message={'Lab Tests Sequence saved'} />, {
      ...toastOptions,
      type: 'success',
    })
    setReload((r) => r + 1)
  }

  const selected = panels.find((p) => p.id === panel)
  samples?.sort((a, b) => {
    return a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1
  })
  return (
    <>
      <hr />
      <div className='sequences-container'>
        <div className='sequences-menu'>
          <select
            className='admin-select'
            value={sample}
            onChange={handleSampleChange}
          >
            <option value={-1}>Choose Sample</option>
            {samples.map((sm) => (
              <option key={sm.id} value={sm.id}>
                {sm.name}
              </option>
            ))}
          </select>
          {panels?.length > 0 ? (
            <select
              title={panels.find((p) => p.id === panel)?.name ?? 'Panel'}
              className='admin-select mt10'
              value={panel}
              onChange={handlePanelChange}
            >
              <option value={-1}>Choose Test</option>
              {panels.map((sm) => (
                <option key={sm.id} value={sm.id}>
                  {sm.name}
                </option>
              ))}
            </select>
          ) : (
            <p className='admin-header'>No Tests Found</p>
          )}
        </div>
        <div className='sequences-content'>
          <SequenceTable panel={selected} onReorder={handleReorder} />
        </div>
      </div>
    </>
  )
}

export default SequencesConfig
