import React, { useRef, useEffect } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import { AdminTableProps } from '../../models'
import { readableDateFormat, shortDateRange } from '../../../../utils/dateUtils'
import LinkButton from './LinkButton'

const PackagesTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = getSearchResults(data, searchParam)

  const packagesTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = packagesTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={packagesTableContainerRef}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Id</th>
            <th>Package Name</th>
            <th>Price</th>
            <th className='table-w-25'>Active</th>
            <th>Test Count</th>
            <th>Created By</th>
            <th>Created On</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => {
              const panelCount =
                d?.panels?.flatMap((dp: any) => dp.lab_tests)?.length ?? 0
              const testCount = d?.lab_tests?.length ?? 0
              return (
                <tr key={d.id}>
                  <td>{d.id}</td>
                  <td>
                    <LinkButton id={d.id} name={d.name} onClick={onEdit} />
                  </td>
                  <td>{d.price}</td>
                  <td>{shortDateRange(d.active_from, d.active_till)}</td>
                  <td>{panelCount + testCount}</td>
                  <td>{d.created_by}</td>
                  <td>{readableDateFormat(new Date(d.created_at))}</td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={7} className='text-center'>
                No Packages Configured
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PackagesTable
