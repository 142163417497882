import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { LABS_PROJECT_ID } from '../../../utils/constants'
import { SequencePanel } from '../models'

export const useSequencePanels = (id: number, reload: number) => {
  const [panels, setPanels] = useState<SequencePanel[]>([])

  useEffect(() => {
    if (id > 0) {
      const intent = 'getPanelsForSequence'
      startSxpProxy(LABS_PROJECT_ID, intent, { sampleId: id })
        .then((data: any) => {
          const details: SequencePanel[] = data.data.panel ?? []
          const transformed: SequencePanel[] = details.map((d) => {
            const tests = d.lab_tests.map((dlt) => ({
              id: dlt.id,
              name: dlt.name,
            }))
            // eslint-disable-next-line camelcase
            return { ...d, lab_tests: tests }
          })
          setPanels(transformed.filter((t) => t.lab_tests?.length > 1) ?? [])
        })
        .catch(() => {
          setPanels([])
        })
    } else {
      setPanels([])
    }
  }, [id, reload])

  return panels
}
