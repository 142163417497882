import { toast } from 'react-toastify'
import { ChangeEvent, useEffect, useState } from 'react'
import { uploadImage } from '../utils/api'
import {
  FILE_SERVER_URL,
  linkCopyText,
  uploadImageText,
  upload,
} from '../utils/constants'
import './form.scss'
import '../features/lms/lms.scss'

const InterpretationImage = ({ imageURL, setImageURL }: any) => {
  const [files, setFiles] = useState<FileList | null>(null)
  const [image, setImage] = useState<string>('')

  useEffect(() => {
    if (!files?.length) {
      setImage('')
      return
    }
    const url = URL.createObjectURL(files[0])
    setImage(url)

    return () => URL.revokeObjectURL(url)
  }, [files])

  const confirm = (e: any) => {
    e.preventDefault()
    if (files?.length) {
      const formData = new FormData()
      formData.append('file', files[0], 'profile.jpeg')
      uploadImage(formData)
        .then((data: any) => {
          const uploaded = data.data[0]
          setImageURL(FILE_SERVER_URL + 'file/' + uploaded)
        })
        .catch((err) => {
          console.error('Failed to store captured image, Reason:', err)
          toast.error('Failed to upload image. Please try again')
        })
    }
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      for (const file of files) {
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
          setFiles(null)
          return
        }
      }
    }
    setFiles(files)
  }

  const copyURL = () => {
    navigator.clipboard.writeText(imageURL)
  }

  return (
    <div className='interpretation-container'>
      <div className='collection-content'>
        <input
          type='file'
          accept='.png, .jpg, .jpeg'
          onChange={handleFileChange}
        />
        <div>
          <button disabled={!image} onClick={(e) => confirm(e)} className='m7'>
            {upload}
          </button>
        </div>
      </div>
      <div className='trim-container'>
        {imageURL ? (
          <span onClick={copyURL}>{imageURL}</span>
        ) : (
          <>{uploadImageText}</>
        )}
        <span>
          {imageURL ? <i className='helper-text'>{linkCopyText}</i> : null}
        </span>
      </div>
    </div>
  )
}

export default InterpretationImage
