import React, { useEffect, useRef } from 'react'

type Props = {
  summary: string
  disabled: boolean
  onChange: (str: string) => void
}

const Summary = ({ summary, disabled, onChange }: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }
  useEffect(() => {
    adjustHeight()
    const textarea = textareaRef.current
    if (textarea) {
      textarea.addEventListener('input', adjustHeight)
      return () => {
        textarea.removeEventListener('input', adjustHeight)
      }
    }
  }, [])
  return (
    <div className='observation-input'>
      <textarea
        name='summary'
        id='summary'
        cols={40}
        rows={2}
        ref={textareaRef}
        value={summary}
        onChange={({ target }) => onChange(target?.value)}
        placeholder='Summary'
        className='notes-area'
      ></textarea>
    </div>
  )
}

export default Summary
