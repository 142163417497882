/* eslint-disable camelcase */
import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Patient } from 'fhir/r4'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID, OPD_PROJECT_ID } from '../../../../utils/constants'
import { saveOrder, testCount } from '../../../labTests/utils'
import { toast } from 'react-toastify'
import { toastOptions } from '../../models'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { setLmsActiveTab, setLmsDateFilter } from '../../lmsSlice'
import { prepareOrderEventTests } from '../../utils'
import {
  LabMode,
  LabOrder,
  LabTest,
  OrderEvent,
  Panel,
} from '../../../labTests/models'
import {
  selectLocationById,
  selectSelectedLocationId,
} from '../../../location/locationSlice'
import ToastMessage from '../ToastMessage'
import PackageTests from '../../../labTests/PackageTests'
import KeycloakService from '../../../../utils/keycloakService'
import LabTests from '../../../labTests/LabTests'
import PatientInfo from '../PatientInfo'
import { FormControl } from '@mui/material'

const LabReference = () => {
  const { id } = useParams()
  const [patient, setPatient] = useState<Patient | null>(null)
  const [order, setOrder] = useState<LabOrder>({})
  const [requestedBy, setRequestedBy] = useState('')
  const [mode, setMode] = useState<LabMode>('')
  const [origin, setCategoryType] = useState('')
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )

  const updateSaveButtonState = useCallback(
    (order: LabOrder, requestedBy: string, origin: string) => {
      const isDisabled =
        !requestedBy || !origin || (mode === 'test' && testCount(order) === 0)
      setIsSaveDisabled(isDisabled)
    },
    [mode]
  )

  useEffect(() => {
    startSxpProxy(LABS_PROJECT_ID, 'getLabOrdersByPatientId', { patientId: id })
      .then((data) => {
        const details = data.data?.lab_orders?.[0] ?? {}
        setOrder(details)
        setRequestedBy(details.requested_by)
        if (details?.package_id) {
          setMode('package')
        } else {
          setMode('test')
        }
        updateSaveButtonState(details, details.requested_by, origin)
      })
      .catch((err) => {
        console.log(err)
      })
    startSxpProxy(OPD_PROJECT_ID, 'getPatientApi', { id: id })
      .then((data) => {
        setPatient(data.data ?? null)
      })
      .catch((err) => {
        console.error(err)
        setPatient(null)
      })
  }, [id, origin, updateSaveButtonState])

  const handleModeChange = (event: any) => {
    const selectedMode = event.target.value as LabMode
    const count = testCount(order)
    if (order.id && count > 0) {
      const switchMode = confirm(
        'Do you wish to change mode? This will remove selected lab tests'
      )
      if (switchMode) {
        saveOrder(
          'patient',
          order,
          id ?? '',
          id ?? '',
          [],
          [],
          location?.resource.name ?? '',
          0,
          0
        ).then((data) => {
          const stateObj: Partial<OrderEvent> = {
            updated_by: KeycloakService.getUsername(),
            order_id: order.id,
            type: 'ORDER_MODIFIED',
            tests: [],
          }
          const updated = data.data?.update_lab_orders?.returning?.[0]
          setOrder(updated)
          startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
            event: stateObj,
          }).then(() => {
            setMode(selectedMode)
          })
        })
      }
    } else {
      setMode(selectedMode)
    }
  }

  const handlePackageSave = (
    packageId: number,
    price: number,
    panels: Panel[],
    labTests: LabTest[]
  ) => {
    if (!requestedBy) {
      alert('Requested By is mandatory for external tests...')
      return
    }
    saveOrder(
      'patient',
      order,
      id ?? '',
      id ?? '',
      panels,
      labTests,
      location?.resource.name ?? '',
      packageId,
      price,
      requestedBy,
      origin
    ).then(() => {
      const stateObj: Partial<OrderEvent> = {
        updated_by: KeycloakService.getUsername(),
        order_id: order.id,
        type: 'ORDER_MODIFIED',
        tests: prepareOrderEventTests(panels, labTests, 'ORDER_MODIFIED'),
      }
      startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
        event: stateObj,
      }).then(() => {
        toast(<ToastMessage message={'Lab Tests Saved'} />, {
          ...toastOptions,
          type: 'success',
        })
        setTimeout(() => {
          dispatch(setLmsDateFilter(new Date().toISOString().slice(0, 10)))
          dispatch(setLmsActiveTab('collection'))
          navigate('/lms/dashboard')
        }, 1500)
      })
    })
  }

  const handleSave = () => {
    toast(<ToastMessage message={'Lab Tests Saved'} />, {
      ...toastOptions,
      type: 'success',
    })
    setTimeout(() => {
      dispatch(setLmsDateFilter(new Date().toISOString().slice(0, 10)))
      dispatch(setLmsActiveTab('collection'))
      navigate('/lms/dashboard')
    }, 1500)
  }

  return (
    <div className='create-lab-order'>
      {patient ? <PatientInfo patient={patient} /> : null}
      <div className='refference-header'>
        <div className='membershipText mt10'>
          <span className='ml10'> Lab Test Refference </span>
        </div>
        <div className='dropdown-container'>
          <FormControl
            sx={{ m: 1 }}
            size='small'
            className={mode === 'test' ? 'dropdown-bottom' : ''}
          >
            <select
              value={mode}
              onChange={handleModeChange}
              className='select-mode'
            >
              <option value=''>
                <em>Select</em>
              </option>
              <option value='package'>Select Package</option>
              <option value='test'>Select Tests</option>
            </select>
          </FormControl>
        </div>
      </div>
      <div className='package-container ml10'>
        <div style={{ marginTop: '1rem' }}>
          {mode === 'package' && (
            <>
              <div style={{ marginTop: '1rem' }}>
                <PackageTests
                  id={order.package_id ?? 0}
                  status={order.status ?? ''}
                  onSave={handlePackageSave}
                />
              </div>
            </>
          )}
          {mode === 'test' && (
            <div>
              <div className='lab-container' style={{ marginTop: '2.5rem' }}>
                <LabTests
                  id={id ?? ''}
                  patientId={id ?? ''}
                  order={order}
                  mode='patient'
                  onSave={handleSave}
                  requestedBy={requestedBy}
                  origin={origin}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LabReference
