/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Practitioner } from 'fhir/r4'
import { useSingleLabOrder } from '../useSingleLabOrder'
import { startSxpProxy } from '../../../../utils/api'
import { ADMIN_PROJECT_ID, LABS_PROJECT_ID } from '../../../../utils/constants'
import { useAppDispatch } from '../../../../app/hooks'
import {
  setLmsActiveTab,
  setLmsOrderId,
  setLmsPatientName,
  setLmsPatientPhone,
  setLmsPreferredCommunication,
} from '../../lmsSlice'
import { TESTING_WRITE, VALIDATION_WRITE } from '../../../../utils/roles'
import { useOrganizations } from '../useOrganizations'
import { OrderEvent, OrderEventTest } from '../../../labTests/models'
import { emptyString } from '../../../Radiology/constants'
import { intent, forms } from '../../../administration/constants'
import {
  CLabel,
  LabResult,
  LmsValues,
  ResultCount,
  labDepartments,
  toastOptions,
} from '../../models'
import {
  encodeNewLineText,
  getApplicableRange,
  groupResultsToTests,
  makeName,
  mapResults,
} from '../../utils'
import KeycloakService from '../../../../utils/keycloakService'
import TestGroup from '../TestGroup'
import LoadingButton from '../../../../components/LoadingButton'
import ToastMessage from '../ToastMessage'
import { getLocationByPrefix } from '../../../patients/utils'
import { getAgeInYears, readableDateFormat } from '../../../../utils/dateUtils'
import { useReactToPrint } from 'react-to-print'
import PrintLabel from '../dashboard/PrintLabel'
import LabelLogo from '../../../../assets/images/sampleLabelPrint.png'
import UploadLabs from '../dashboard/UploadLabs'

const EnterOrder = () => {
  const [apiLoading, setApiLoading] = useState(false)
  const [reload, setReload] = useState(1)
  const { id } = useParams()
  const { order, patient } = useSingleLabOrder(id ?? '', reload)
  const [results, setResults] = useState<LabResult[]>([])
  const [active, setActive] = useState(0)
  const [updated, setUpdated] = useState<Set<number>>(() => new Set())
  const [summary, setSummary] = useState(order?.summary ?? '')
  const [editing, setEditing] = useState(false)
  const organizations = useOrganizations()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [totalcount, setTotalCount] = useState<Record<string, ResultCount>>({})
  const [labDepartments, setLabDepartments] = useState<labDepartments[]>([])
  const labelRef = useRef(null)
  const labelPageStyle = `
    @page {
      size: 50mm 25mm;
    }
    @media print {
      @page {  
        size: 50mm 25mm landscape;
        margin: 0mm !important;
      }
    }
  `

  const handleLabelPrint = useReactToPrint({
    content: () => labelRef.current,
    pageStyle: labelPageStyle,
  })
  const filterLabResults = (results: any) => {
    const uniqueResults: any = {}
    results.forEach((result: any) => {
      if (result?.referred_out) {
        const panelId = result.lab_test.panel_id
        if (!uniqueResults[panelId]) {
          uniqueResults[panelId] = result
        }
      }
    })

    return Object.values(uniqueResults)
  }
  const filteredLabResults = order?.lab_results
    ? filterLabResults(order?.lab_results)
    : ''
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, intent.getLabInchargeList)
      .then((res) => {
        const practitioners = res?.data?.entry?.filter(
          (e: { resource: { resourceType: string } }) =>
            e.resource?.resourceType === forms.practitioner
        )
        const data = practitioners?.map((e: { resource: Practitioner }) => {
          const firstName =
            e.resource?.name?.[0]?.given?.join(emptyString) ?? emptyString
          const lastName = e.resource?.name?.[0]?.family ?? emptyString
          const signature = e.resource?.photo?.[1]?.url ?? ''
          return {
            id: e.resource?.id,
            name: firstName.concat(' ', lastName),
            signature: signature,
          }
        })
        setLabDepartments(data)
      })
      .catch((err) => console.error(err))
  }, [reload])

  const updateSet = (i: number) => {
    setUpdated((pr) => new Set(pr).add(i))
  }

  useEffect(() => {
    setSummary(order?.summary ?? '')
  }, [order])

  useEffect(() => {
    if (id) {
      const state = {
        labOrderId: id,
      }
      startSxpProxy(LABS_PROJECT_ID, 'getLabResultByLabOrderId', state)
        .then((data) => {
          const labResults: LabResult[] = data.data.lab_result ?? []
          setResults(labResults)
          setEditing(false)
        })
        .catch(() => {
          setResults([])
        })
    }
  }, [id, reload])

  const handleReferredOutChange = (val: boolean, index: number) => {
    setResults((r) =>
      r.map((rs) => {
        if (index === rs.lab_test.panel.id && rs.test_status !== 'REJECTED') {
          rs.referred_out = !val
          updateSet(rs.id)
        }
        return rs
      })
    )
  }

  const handleReferredToChange = (val: number, index: number) => {
    setResults((r) =>
      r.map((rs) => {
        if (index === rs.lab_test.panel.id && rs.test_status !== 'REJECTED') {
          rs.referred_to = val
          updateSet(rs.id)
        }
        return rs
      })
    )
  }

  const handleDocUrlChange = (val: string, index: number) => {
    setResults((r) =>
      r.map((rs) => {
        if (index === rs.lab_test.panel.id && rs.test_status !== 'REJECTED') {
          rs.document_url = val
          updateSet(rs.id)
        }
        return rs
      })
    )
  }

  const handleValueChange = (val: string, index: number) => {
    setResults((r) =>
      r.map((rs) => {
        if (rs.id === index) {
          rs.value = val
        }
        return rs
      })
    )
    updateSet(index)
  }

  const handleExtraValueChange = (val: string, index: number) => {
    setResults((r) =>
      r.map((rs) => {
        if (rs.id === index) {
          rs.extra_value = val
        }
        return rs
      })
    )
    updateSet(index)
  }

  const handleNotesChange = (val: string, index: number) => {
    setResults((r) =>
      r.map((rs) => {
        if (rs.id === index) {
          rs.observation = val
        }
        return rs
      })
    )
    updateSet(index)
  }

  const handleValuesChange = (values: LmsValues, index: number) => {
    setResults((r) =>
      r.map((rs) => {
        if (rs.id === index) {
          rs.values = values
        }
        return rs
      })
    )
    updateSet(index)
  }

  const handleEnter = (testId: number) => {
    const found = ids.findIndex((i) => i === testId)
    if (found !== -1) {
      setActive(found + 1)
    } else {
      setActive((id) => id + 1)
    }
  }

  const handleSave = () => {
    if (!KeycloakService.hasRole([TESTING_WRITE])) {
      return
    }
    for (const result of results) {
      if (result.referred_out && !result.referred_to) {
        toast(
          <ToastMessage message='referredTo is mandatory when referred is checked' />,
          { ...toastOptions, type: 'error' }
        )
        return
      }
    }
    setApiLoading(true)
    const now = new Date().toISOString()
    const user = KeycloakService.getUsername()
    const lastEventTests = order?.order_events?.[0]?.tests ?? []
    const updatedTests = results
      .filter((rs) => updated.has(rs.id))
      .map((r) => r.lab_test_id)
    const updatedTestSet = new Set(updatedTests)
    const currentTests: OrderEventTest[] = lastEventTests.map((lt) => {
      if (updatedTestSet.has(lt.test_id)) {
        return {
          test_id: lt.test_id,
          status: 'ENTERED',
          updatedBy: user,
          updatedOn: now,
        }
      }
      return lt
    })
    const stateObj: Partial<OrderEvent> = {
      updated_by: user,
      order_id: order?.id,
      type: 'RESULTS_ENTERED',
      tests: currentTests,
    }
    startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
      event: stateObj,
    }).then(() => {
      const mapped = results.map((rs) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { lab_order, lab_test, organization, ...rest } = rs
        const applicableRange = getApplicableRange(
          lab_test.test_result_limits,
          patient
        )
        const resultUpdated = updated.has(rs.id)
        const updatedResult: Partial<LabResult> = {
          ...rest,
          value: encodeNewLineText(rs.value),
          values: rs.values,
          extra_value: rs.extra_value,
          observation: encodeNewLineText(rs.observation),
          document_url: rs.document_url || undefined,
          result_limit_id: applicableRange?.id,
          referred_out: rs.referred_out,
          referred_to: rs.referred_out ? rs.referred_to : null,
          test_status: resultUpdated ? 'ENTERED' : rs.test_status,
          result_entered_on: resultUpdated ? now : rs.result_entered_on,
          result_entered_by: resultUpdated
            ? KeycloakService.getFullname()
            : rs.result_entered_by,
          result_entered_by_id: resultUpdated
            ? KeycloakService.getSub()
            : rs.result_entered_by_id,
        }
        return { where: { id: { _eq: rs.id } }, _set: updatedResult }
      })
      const intent = 'updateLabResults'
      const state = {
        results: mapped,
      }
      startSxpProxy(LABS_PROJECT_ID, intent, state)
        .then(() => {
          setApiLoading(false)
          setReload((r) => r + 1)
          setUpdated(new Set())
          toast(<ToastMessage message='Lab Results Saved' />, {
            ...toastOptions,
            type: 'success',
          })
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }

  const handleSubmit = (testResults: LabResult[]) => {
    if (!KeycloakService.hasRole([TESTING_WRITE])) {
      return
    }
    setApiLoading(true)
    const now = new Date().toISOString()
    const user = KeycloakService.getUsername()
    const lastEventTests = order?.order_events?.[0]?.tests ?? []
    const selectedIds = testResults.map((tr) => tr.lab_test_id)
    const currentTests: OrderEventTest[] = lastEventTests.map((lt) => {
      if (selectedIds.includes(lt.test_id)) {
        return {
          test_id: lt.test_id,
          status: 'SUBMITTED',
          updatedBy: user,
          updatedOn: now,
        }
      }
      return lt
    })
    const stateObj: Partial<OrderEvent> = {
      updated_by: user,
      order_id: order?.id,
      type: 'RESULTS_SUBMITTED',
      tests: currentTests,
    }
    startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
      event: stateObj,
    }).then(() => {
      const intent = 'updateLabOrdersShort'
      const updated = {
        status: 'SUBMITTED',
      }
      const state = {
        order: updated,
        labOrderId: id,
      }
      startSxpProxy(LABS_PROJECT_ID, intent, state)
        .then(() => {
          const mapped = testResults.map((rs) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { lab_order, lab_test, organization, ...rest } = rs
            const updatedResult: Partial<LabResult> = {
              ...rest,
              test_status: 'SUBMITTED',
              result_entered_on: now,
              result_entered_by: KeycloakService.getFullname(),
              result_entered_by_id: KeycloakService.getSub(),
            }
            return { where: { id: { _eq: rs.id } }, _set: updatedResult }
          })
          startSxpProxy(LABS_PROJECT_ID, 'updateLabResults', {
            results: mapped,
          })
            .then(() => {
              setApiLoading(false)
              setReload((r) => r + 1)
              toast(<ToastMessage message='Lab Results Submitted' />, {
                position: 'bottom-center',
                hideProgressBar: true,
                closeButton: false,
                theme: 'dark',
                autoClose: 2000,
              })
            })
            .catch((err) => {
              throw err
            })
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }

  const handleCancelEdit = (isEdit: boolean) => {
    if (isEdit) {
      setReload((r) => r + 1)
    } else {
      setEditing(true)
    }
  }

  const handleApprove = (
    testResults: LabResult[],
    reason: string,
    pathologist?: string
  ) => {
    if (!KeycloakService.hasRole([VALIDATION_WRITE])) {
      return
    }
    setApiLoading(true)
    const now = new Date().toISOString()
    const user = KeycloakService.getUsername()
    const selectedIds = testResults.map((tr) => tr.lab_test_id)
    const lastEventTests = order?.order_events?.[0]?.tests ?? []
    const currentTests: OrderEventTest[] = lastEventTests.map((lt) => {
      if (selectedIds.includes(lt.test_id)) {
        return {
          test_id: lt.test_id,
          status: 'APPROVED',
          updatedBy: user,
          updatedOn: now,
        }
      }
      return lt
    })
    const pathologistName = labDepartments.find(
      (e) => e.id === pathologist
    )?.name
    const stateObj: Partial<OrderEvent> = {
      updated_by: user,
      order_id: order?.id,
      type: 'RESULTS_APPROVED',
      tests: currentTests,
      comments: reason,
      authorised_by_id: pathologist,
      authorised_by_name: pathologistName,
    }
    startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
      event: stateObj,
    }).then(() => {
      const intent = 'updateLabOrdersShort'
      const updated = {
        status: currentTests.every((ct) => ct.status === 'APPROVED')
          ? 'COMPLETED'
          : 'SUBMITTED',
        summary: summary,
        validation_completed_on: new Date().toISOString(),
        validated_by_id: KeycloakService.getSub(),
        validated_by_name: KeycloakService.getFullname(),
        authorised_by_id: pathologist,
        authorised_by_name: pathologistName,
      }
      const state = {
        order: updated,
        labOrderId: id,
      }
      startSxpProxy(LABS_PROJECT_ID, intent, state)
        .then(() => {
          const mapped = testResults.map((rs) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { lab_order, lab_test, organization, ...rest } = rs
            const updatedResult: Partial<LabResult> = {
              ...rest,
              test_status: 'APPROVED',
              validated_on: now,
              validated_by: KeycloakService.getFullname(),
              authorised_by_id: pathologist,
              authorised_by_name: pathologistName,
            }
            if (reason) {
              updatedResult.summary_reason = reason
            }
            return { where: { id: { _eq: rs.id } }, _set: updatedResult }
          })
          startSxpProxy(LABS_PROJECT_ID, 'updateLabResults', {
            results: mapped,
          }).then(() => {
            setApiLoading(false)
            setReload((r) => r + 1)
            toast(<ToastMessage message='Lab Order Validated' />, {
              position: 'bottom-center',
              hideProgressBar: true,
              closeButton: false,
              theme: 'dark',
              autoClose: 2000,
            })
            dispatch(setLmsOrderId(order?.id?.toString() ?? ''))
            dispatch(setLmsPatientPhone(patient?.telecom?.[0]?.value ?? ''))
            dispatch(setLmsPatientName(makeName(patient?.name)))
            dispatch(setLmsActiveTab('dispatch'))
            setTimeout(() => {
              navigate('/lms/dashboard')
              if (patient?.telecom?.[3].value === 'yes') {
                dispatch(setLmsPreferredCommunication('sms'))
              } else if (patient?.telecom?.[3].value === 'no') {
                dispatch(setLmsPreferredCommunication('whatsapp'))
              } else {
                dispatch(setLmsPreferredCommunication('none'))
              }
            }, 1000)
          })
        })
        .catch((err) => {
          console.error(err)
        })
    })
  }

  const handleReject = (testResults: LabResult[], reason: string) => {
    if (!reason) {
      alert('Select a reason to reject')
      return
    }
    setApiLoading(true)
    const now = new Date().toISOString()
    const user = KeycloakService.getUsername()
    const lastEventTests = order?.order_events?.[0]?.tests ?? []
    const updatedTests = testResults.map((r) => r.lab_test_id)
    const currentTests: OrderEventTest[] = lastEventTests.map((lt) => {
      if (updatedTests.includes(lt.test_id)) {
        return {
          test_id: lt.test_id,
          status: 'REJECTED',
          updatedBy: user,
          updatedOn: now,
        }
      }
      return lt
    })
    const stateObj: Partial<OrderEvent> = {
      comments: reason,
      updated_by: user,
      order_id: order?.id,
      type: 'RESULTS_REJECTED',
      tests: currentTests,
    }
    startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
      event: stateObj,
    }).then(() => {
      const valId = KeycloakService.getSub()
      const valName = KeycloakService.getFullname()
      const rejectResults = testResults.map((rs) => {
        const updatedResult: Partial<LabResult> = {
          test_status: 'REJECTED',
          rejection_reason: reason,
          rejected_on: now,
          rejected_by_id: valId,
          rejected_by_name: valName,
        }
        return { where: { id: { _eq: rs.id } }, _set: updatedResult }
      })
      const intent = 'updateLabResults'
      const state = {
        results: rejectResults,
      }
      startSxpProxy(LABS_PROJECT_ID, intent, state)
        .then(() => {
          const updated = {
            status: 'REJECTED',
          }
          const newState = {
            order: updated,
            labOrderId: id,
          }
          startSxpProxy(LABS_PROJECT_ID, 'updateLabOrdersShort', newState)
            .then(() => {
              setApiLoading(false)
              setReload((r) => r + 1)
              toast(
                <ToastMessage message='Lab Results Validated [Some Rejected]' />,
                {
                  position: 'bottom-center',
                  hideProgressBar: true,
                  closeButton: false,
                  theme: 'dark',
                  autoClose: 2000,
                }
              )
              setTimeout(() => {
                dispatch(setLmsActiveTab('collection'))
                navigate('/lms/dashboard')
              }, 1000)
            })
            .catch((err) => {
              console.error(err)
            })
        })
        .catch((err) => {
          console.error(err)
        })
    })
  }

  const groupedTests = groupResultsToTests(results)
  const ids = Object.keys(groupedTests).flatMap((ok) => {
    return groupedTests[parseInt(ok)]
      .filter((gt) => gt.test_status !== 'REJECTED')
      .map((gtf) => gtf.id)
  })
  const countResults = async () => {
    const results = await mapResults([id])
    setTotalCount(results)
  }
  useEffect(() => {
    countResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  const res = totalcount[id as string]
  const labelOptions = (): CLabel => ({
    name: makeName(patient?.name),
    gender: patient?.gender,
    age: patient?.birthDate ? getAgeInYears(patient?.birthDate) : '-',
    patientId: patient?.identifier?.[2]?.value,
    code: order?.lr_number ?? patient?.identifier?.[2]?.value,
  })
  return (
    <div>
      <table className='data-table table-fixed admin-table relative'>
        <thead style={{ position: 'sticky', top: '0px' }}>
          <tr>
            <th className='table-w-5'>Sl No</th>
            <th className='table-w-11'>LR.Number</th>
            <th>UHID</th>
            <th>Name</th>
            <th>Mobile No.</th>
            <th>Operating Unit</th>
            <th className='table-w-5'>Service</th>
            <th className='table-w-10'>Ordered On</th>
            <th className='table-w-6'>Total</th>
            <th className='table-w-6'>Pending</th>
            <th className='table-w-6'>Referred</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{order?.lr_number}</td>
            <td>{patient?.identifier?.[2]?.value}</td>
            <td>{makeName(patient?.name)}</td>
            <td>{patient?.telecom?.[0]?.value}</td>
            <td>
              {getLocationByPrefix(
                patient?.identifier?.[2]?.value ??
                  patient?.identifier?.[0]?.value ??
                  '-'
              )}
            </td>
            <td>{order?.origin}</td>
            <td>
              {order?.ordered_on
                ? readableDateFormat(new Date(order?.ordered_on ?? ''))
                : '-'}
            </td>
            <td>{res?.total ?? 0}</td>
            <td>{res?.pending ?? 0}</td>
            <td className='collect-content border-none'>
              {res?.referred ?? 0}
              {filteredLabResults.length > 0 && (
                <UploadLabs docs={filteredLabResults} />
              )}
            </td>
            <td>
              {
                <>
                  <div className='result-actions'>
                    <div className='display-none'>
                      <PrintLabel data={labelOptions()} ref={labelRef} />
                    </div>
                    <button title='Print Label' onClick={handleLabelPrint}>
                      <img src={LabelLogo} alt='Print Label' />
                    </button>
                  </div>
                </>
              }
            </td>
          </tr>
        </tbody>
      </table>
      {order?.id ? (
        results.length > 0 ? (
          <div className='collect-tests'>
            <p className='admin-header'>Enter Results</p>
            {Object.keys(groupedTests).map((k) => (
              <div key={k}>
                <TestGroup
                  key={k}
                  activeId={ids[active]}
                  tests={groupedTests[parseInt(k)]}
                  patient={patient}
                  onReferredOutChange={handleReferredOutChange}
                  onReferredToChange={handleReferredToChange}
                  onDocUrlChange={handleDocUrlChange}
                  onValueChange={handleValueChange}
                  onNotesChange={handleNotesChange}
                  onExtraValueChange={handleExtraValueChange}
                  onValuesChange={handleValuesChange}
                  onEnter={handleEnter}
                  isReferred={false}
                  disabled={order?.status === 'COMPLETED'}
                  organizations={organizations}
                  orderStatus={order?.status}
                  valuesUpdated={updated.size > 0}
                  onSubmit={handleSubmit}
                  onApprove={handleApprove}
                  onReject={handleReject}
                  onEditCancel={handleCancelEdit}
                  apiLoading={apiLoading}
                  labDepartments={labDepartments}
                />
              </div>
            ))}
            {order?.status !== 'COMPLETED' &&
              updated.size !== 0 &&
              !editing &&
              KeycloakService.hasRole([TESTING_WRITE]) && (
                <LoadingButton
                  title='Save'
                  loading={apiLoading}
                  compact
                  onClick={handleSave}
                />
              )}
          </div>
        ) : (
          <p className='all-collected'>No Results found</p>
        )
      ) : (
        <span className='all-collected'>
          Unable to Find Order with the given Id
        </span>
      )}
    </div>
  )
}

export default EnterOrder
