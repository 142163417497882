import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { uploadImage } from '../../../utils/api'
import { Patient } from 'fhir/r4'
import { FILE_SERVER_URL } from '../../../utils/constants'
import { makeName } from '../utils'
import { TESTING_WRITE, VALIDATION_WRITE } from '../../../utils/roles'
import { fullDateTimeFormat } from '../../../utils/dateUtils'
import { emptyString } from '../../Radiology/constants'
import {
  LabResult,
  LmsValues,
  Organization,
  labDepartments,
  selectPathologist,
  tableSummaryHeadText,
} from '../models'
import LoadingButton from '../../../components/LoadingButton'
import KeycloakService from '../../../utils/keycloakService'
import TestRow from './TestRow'

type Props = {
  activeId: number
  tests: LabResult[]
  patient: Patient | null
  onReferredOutChange?: (referred: boolean, panelId: number) => void
  onReferredToChange?: (referred: number, panelId: number) => void
  onDocUrlChange: (
    referred: string,
    panelId: number,
    patientId?: string
  ) => void
  onValueChange: (val: string, index: number) => void
  onNotesChange: (val: string, index: number) => void
  onExtraValueChange: (val: string, index: number) => void
  onValuesChange: (values: LmsValues, index: number) => void
  onEnter: (id: number) => void
  isReferred: boolean
  disabled: boolean
  organizations: Organization[]
  onSubmit?: (results: LabResult[]) => void
  onApprove?: (
    results: LabResult[],
    reason: string,
    pathologist?: string
  ) => void
  onReject?: (results: LabResult[], reason: string) => void
  orderStatus?: string
  valuesUpdated?: boolean
  onEditCancel?: (edit: boolean) => void
  apiLoading: boolean
  labDepartments?: labDepartments[]
}

const TestGroup = ({
  activeId,
  tests,
  patient,
  onReferredOutChange,
  onReferredToChange,
  onDocUrlChange,
  onValueChange,
  onNotesChange,
  onExtraValueChange,
  onValuesChange,
  onEnter,
  isReferred,
  disabled,
  organizations,
  onSubmit,
  onApprove,
  onReject,
  valuesUpdated,
  onEditCancel,
  apiLoading,
  labDepartments,
}: Props) => {
  const docsRef = useRef<HTMLInputElement | null>(null)
  const test = tests.find((t) => t.test_status !== 'REJECTED') ?? tests[0]
  const [reason, setReason] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [lab, setLab] = useState<labDepartments[]>([])
  const [pathologist, setPathologist] = useState<string>('')
  const [isExpanded, setIsExpanded] = useState(true)
  useEffect(() => {
    if (labDepartments && labDepartments.length > 0) {
      setLab(labDepartments)
    }
  }, [labDepartments])
  const handleFileButton = () => {
    docsRef.current?.click()
  }

  const handleEdit = () => {
    setIsEdit(!isEdit)
    onEditCancel?.(isEdit)
  }

  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0]
    if (file) {
      const formData = new FormData()
      formData.append('file', file, 'report.pdf')
      uploadImage(formData)
        .then((data) => {
          const uploaded = data.data[0]
          onDocUrlChange(
            `${FILE_SERVER_URL}file/${uploaded}`,
            test.lab_test.panel.id,
            patient?.id
          )
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      onDocUrlChange('', test.lab_test.panel.id, patient?.id)
    }
  }

  return (
    <section className='results-section'>
      <div className='test-group'>
        <div className='test-name'>
          {test?.test_status === 'SUBMITTED' && (
            <button onClick={handleEdit}>
              {isEdit ? 'Cancel Editing' : 'Edit'}
            </button>
          )}
          <button onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Minimize' : 'Expand'}
          </button>

          <h3 className='test-name'>
            {test.lab_test.panel.name}
            {isReferred && (
              <span>
                &nbsp;[{makeName(patient?.name)} -{' '}
                {patient?.identifier?.[2]?.value ??
                  patient?.identifier?.[0]?.value ??
                  ''}
                ]
              </span>
            )}
          </h3>
        </div>
        <div className='referred'>
          {isReferred ? (
            <span>
              Referred To:{' '}
              {organizations.find((o) => o.id === test.referred_to)?.name ?? ''}
            </span>
          ) : (
            <>
              <input
                type='checkbox'
                checked={test.referred_out ?? false}
                disabled={
                  disabled ||
                  test.test_status === 'APPROVED' ||
                  test.test_status === 'SUBMITTED'
                }
                onChange={() =>
                  onReferredOutChange?.(
                    test.referred_out ?? false,
                    test.lab_test.panel.id
                  )
                }
              />
              <span> Refer Out </span>
              {test.referred_out ? (
                <select
                  name='Referred'
                  value={test.referred_to ?? 0}
                  disabled={
                    disabled ||
                    test.test_status === 'APPROVED' ||
                    test.test_status === 'SUBMITTED'
                  }
                  onChange={(ev) =>
                    onReferredToChange?.(
                      parseInt(ev.target.value),
                      test.lab_test.panel.id
                    )
                  }
                >
                  <option value={0}>Choose an option</option>
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
              ) : null}
            </>
          )}
        </div>
        <div className='documents'>
          <label htmlFor='docUrl'>
            <button
              disabled={
                disabled ||
                test.test_status === 'APPROVED' ||
                test.test_status === 'SUBMITTED'
              }
              onClick={handleFileButton}
            >
              Upload
            </button>
          </label>
          <input
            id='docUrl'
            ref={docsRef}
            type='file'
            accept='application/pdf'
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          {test.document_url ? (
            <a
              href={test.document_url}
              target='_blank'
              rel='noreferrer'
              download
            >
              Report
            </a>
          ) : (
            <p>No Reports</p>
          )}
        </div>
      </div>
      {isExpanded && (
        <>
          {tests.map((t) => (
            <TestRow
              key={t.id}
              test={t}
              patient={patient}
              activeId={activeId}
              onValueChange={onValueChange}
              onNotesChange={onNotesChange}
              onExtraValueChange={onExtraValueChange}
              onValuesChange={onValuesChange}
              onEnter={onEnter}
              disabled={
                disabled ||
                test.test_status === 'APPROVED' ||
                test.test_status === 'SUBMITTED'
              }
              isReferred={isReferred}
              isEdit={isEdit}
            />
          ))}
          {!valuesUpdated &&
            !isReferred &&
            KeycloakService.hasRole([TESTING_WRITE]) &&
            tests?.every(
              (t) =>
                t.test_status === 'REJECTED' ||
                (t.test_status === 'ENTERED' && (t.value || t.values?.summary))
            ) && (
              <div className='test-group-actions'>
                <LoadingButton
                  title='Submit'
                  loading={apiLoading}
                  compact
                  onClick={() => {
                    onSubmit?.(tests)
                  }}
                />
              </div>
            )}
          {test.test_status === 'SUBMITTED' &&
            (!valuesUpdated || isEdit) &&
            !isReferred &&
            KeycloakService.hasRole([VALIDATION_WRITE]) && (
              <div className='test-group-actions'>
                <textarea
                  className='result-summary'
                  name='Comments'
                  placeholder='Comments'
                  value={reason}
                  onChange={(ev) => setReason(ev.target.value)}
                  cols={80}
                  rows={7}
                ></textarea>
                <div className='approve'>
                  {test.referred_out ? (
                    <button
                      title='Approve'
                      className='base-button create-button'
                      onClick={() => {
                        onApprove?.(tests, reason)
                      }}
                    >
                      {tableSummaryHeadText.approve}
                    </button>
                  ) : (
                    <button
                      title='Approve'
                      disabled={apiLoading || pathologist.length === 0}
                      className='base-button create-button'
                      onClick={() => {
                        onApprove?.(tests, reason, pathologist)
                      }}
                    >
                      {tableSummaryHeadText.approve}
                    </button>
                  )}
                  {!isEdit && (
                    <LoadingButton
                      title='Reject'
                      loading={apiLoading}
                      compact
                      onClick={() => onReject?.(tests, reason)}
                    />
                  )}
                  {!test.referred_out && (
                    <div>
                      <select
                        className='pathology-input'
                        value={pathologist}
                        onChange={(e) => setPathologist(e.target.value)}
                      >
                        <option value={emptyString}>{selectPathologist}</option>
                        {lab.map((org: any) => (
                          <option key={org.id} value={org.id}>
                            {org.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            )}
          {test?.validated_on && (
            <p className='admin-header'>
              Validated on {fullDateTimeFormat(new Date(test.validated_on))}{' '}
              {test.validated_by && <span>[{test.validated_by}]</span>}
            </p>
          )}
        </>
      )}
    </section>
  )
}

export default TestGroup
