import { ProvenanceData } from './patientsSlice'
import { Practitioner, Provenance } from 'fhir/r4'
import { makeName } from '../lms/utils'
import { LocationData } from '../location/locationSlice'
import { getTotalAge } from '../../utils/dateUtils'

export const getProvenanceData = (
  entries: any
): Record<string, ProvenanceData> => {
  const practitioners: Practitioner[] = entries.filter(
    (e: Practitioner) => e.resourceType === 'Practitioner'
  )
  const practitionerObj: Record<string, string> = practitioners.reduce(
    (prev: Record<string, string>, curr) => {
      if (curr.id) {
        prev[curr.id] = makeName(curr.name)
      }
      return prev
    },
    {}
  )
  const provenances: Provenance[] = entries.filter(
    (e: Provenance) => e.resourceType === 'Provenance'
  )
  provenances.sort((a, b) => {
    if (!a.recorded) {
      return -1
    }
    if (!b.recorded) {
      return 1
    }
    return a.recorded < b.recorded ? -1 : 1
  })
  return provenances.reduce((prev: Record<string, ProvenanceData>, curr) => {
    const pId = curr.target?.[0]?.reference?.split('/')?.[1] ?? ''
    if (pId) {
      let existing = prev[pId] ?? {}
      const practitionerId =
        curr.agent?.[0]?.who?.reference?.split('/')?.[1] ?? ''
      const activity = curr.activity?.coding?.[0]?.code
      if (activity === 'CREATE') {
        existing = {
          ...existing,
          createdOn: curr.recorded,
          createdBy: practitionerObj[practitionerId],
        }
      }
      if (activity === 'UPDATE') {
        existing = {
          ...existing,
          updatedOn: curr.recorded,
          updatedBy: practitionerObj[practitionerId],
        }
      }
      prev[pId] = existing
    }
    return prev
  }, {})
}

export const getLocationByPrefix = (id: string): string => {
  const prefix = id.slice(0, 6)
  switch (prefix) {
    case 'MUH001':
      return 'Amalapuram'
    case 'MUC001':
      return 'Razole'
    case 'MUC002':
      return 'Tatipaka'
    case 'MUC003':
      return 'Rajahmundry'
    case 'MUA001':
      return 'Malkipuram'
    case 'MUC006':
      return 'Bhimavaram'
    case 'MUC005':
      return 'Kothapeta'
    case 'MUC004':
      return 'Mummidivaram'
    case 'MUH777':
      return 'Mental Health App'
    case 'MUH888':
      return 'Dengue App'
    case 'KIMH01':
      return 'KIMS Amalapuram'
    default:
      return 'Unknown'
  }
}

export const getLocationAddress = (uhid: string, locations: LocationData[]) => {
  if (uhid) {
    const prefix = uhid.slice(0, 6)
    return locations.find((l) =>
      l.resource.identifier?.some((lri) => lri.value === prefix)
    )?.resource
  }
}
export const capitalizeFirstLetter = (word?: string) => {
  if (!word) return '-'

  return word.charAt(0).toUpperCase() + word.slice(1)
}
export const formatPatient = (e: any) => {
  const name = makeName(e?.name)
  const age = getTotalAge(e?.birthDate)
  const gender = capitalizeFirstLetter(e?.gender)
  const tel1 = e?.telecom?.find((prt: { use: string }) => prt.use === 'mobile')
  const tel2 = e?.telecom?.find((prt: { use: string }) => prt.use === 'home')
  return {
    id: e?.id,
    name: name,
    age: age,
    gender: gender,
    mobile: tel1?.value ?? tel2?.value ?? '',
  }
}
