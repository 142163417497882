import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import differenceInYears from 'date-fns/differenceInYears'
import intervalToDuration from 'date-fns/intervalToDuration'

export const isoDateFormat = (date: Date): string => {
  if (!date) return ''
  return format(date, 'yyyy-MM-dd')
}

export const appointmentDateFormat = (date: string): string => {
  if (!date) return ''
  const dt = new Date(date)
  return format(dt, 'MMM do, HH:mm')
}

export const readableDateFormat = (date: Date): string => {
  if (!date) return ''
  return format(date, 'MMM do, yyyy')
}

export const readableDateTime = (date: string) => {
  if (!date) return ''
  return format(parseISO(date), 'dd-MMM-yyyy, p')
}

export const readableSlotFormat = (date: Date): string => {
  if (!date) return ''
  return format(date, 'HH:mm')
}

export const makeConfigString = (start: string, end?: string): string => {
  return end
    ? `${readableDateFormat(new Date(start))} - ${readableDateFormat(
        new Date(end)
      )}`
    : readableDateFormat(new Date(start))
}

export const shortDateRange = (start?: string, end?: string): string => {
  if (!start || !end) return ''
  const sDate = format(new Date(start), 'MMM do')
  const eDate = format(new Date(end), 'MMM do')
  return `${sDate} to ${eDate}`
}

export const fullDateTimeFormat = (date: Date): string => {
  if (!date) return ''
  return format(date, 'yyyy-MM-dd, HH:mm')
}

export const readableDateTimeFormat = (date: Date): string => {
  if (!date) return ''
  return format(date, 'MMM do yyyy, HH:mm:ss')
}

export const readableDateTimeFormats = (date: Date): string => {
  if (!date) return ''
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  return format(utcDate, 'MMM do yyyy, HH:mm:ss')
}

export const invoiceDateTimeFormat = (date: Date): string => {
  if (!date) return ''
  return format(date, 'dd-MM-yyyy HH:mm')
}

export const getAgeInYears = (date?: string): number | undefined => {
  if (!date) {
    return undefined
  }
  const dt = new Date(date)
  return differenceInYears(new Date(), dt)
}

export const getTotalAge = (birthDate?: string | Date) => {
  if (!birthDate) {
    return '-'
  }

  const now = new Date()
  const parsedBirthDate =
    typeof birthDate === 'string' ? new Date(birthDate) : birthDate

  if (!isNaN(parsedBirthDate.getTime())) {
    const duration = intervalToDuration({ start: parsedBirthDate, end: now })

    const yearString = duration.years === 1 ? 'Yr' : 'Yrs'
    const monthString = duration.months === 1 ? 'Month' : 'Months'
    const dayString = duration.days === 1 ? 'Day' : 'Days'

    if (!duration.years) {
      if (!duration.months) {
        return `${duration.days} ${dayString}`
      }
      return `${duration.months} ${monthString}, ${duration.days} ${dayString}`
    } else {
      return `${duration.years} ${yearString}`
    }
  }

  return '-'
}
