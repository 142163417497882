import { ChangeEvent, FormEvent, useState } from 'react'
import Asterisk from '../../../components/Asterisk'
import { AppLocation } from '../../location/locationSlice'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toastOptions } from '../../lms/models'
import ToastMessage from '../../lms/components/ToastMessage'
import { Box, Button } from '@mui/material'

const emptyLocation: AppLocation = {
  id: '',
  name: '',
  prefix: '',
  ipPrefix: '',
  value: '',
  address: '',
  email: '',
  phone: '',
  status: '',
  dlNumber: '',
  hasInventory: '',
  type: '',
  node: '',
  cluster: '',
  gst: '',
}

type Props = {
  loc: AppLocation | null
}

const LocationForm = ({ loc }: Props) => {
  const [location, setLocation] = useState<AppLocation>(loc ?? emptyLocation)
  const navigate = useNavigate()
  const handleChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, type, value } = ev.target

    if (type === 'checkbox') {
      const isChecked = (ev.target as HTMLInputElement).checked
      setLocation((prev) => ({ ...prev, [name]: isChecked ? 'yes' : 'no' }))
    } else {
      setLocation((prev) => ({ ...prev, [name]: value }))
    }
  }

  const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    if (!/^[a-z A-Z]+$/.test(location.name)) {
      toast.error('Please enter your name in characters')
      return false
    }
    const {
      name,
      prefix,
      address,
      email,
      phone,
      ipPrefix,
      dlNumber,
      hasInventory,
      type,
      gst,
    } = location

    const state = {
      id: '',
      locationName: name?.trim(),
      locationPrefix: prefix?.trim(),
      ipPrefix: ipPrefix?.trim(),
      address,
      email,
      mobile: phone,
      status: 'active',
      dlNumber,
      hasInventory,
      type,
      gst,
    }

    if (loc) {
      state.id = loc.id
      state.status = loc.status
      startSxpProxy(ADMIN_PROJECT_ID, 'editLocation', state)
        ?.then(() => {
          toast?.success('Location is edited', toastOptions)
          navigate('/administration/locations')
        })
        .catch(() => {
          toast.error('Failed to Edit Location...')
        })
    } else {
      startSxpProxy(ADMIN_PROJECT_ID, 'createLocation', state)
        ?.then(() => {
          toast?.success('Location is created', toastOptions)
          navigate('/administration/locations')
        })
        .catch((error) => {
          const errorMessage = error?.response?.data

          if (errorMessage) {
            const nameMatch = errorMessage.match(
              /index: name_1 dup key: { : "(.*?)" }/i
            )
            const prefixMatch = errorMessage.match(
              /index: prefix_1 dup key: { : "(.*?)" }/i
            )
            let customMessage

            if (nameMatch) {
              customMessage = `Name ${nameMatch[1]} already exists.`
            } else if (prefixMatch) {
              customMessage = `Prefix ${prefixMatch[1]} already exists.`
            } else {
              customMessage = errorMessage
            }

            toast.error(<ToastMessage message={customMessage} />, toastOptions)
          } else {
            toast.error(
              <ToastMessage message='Failed to create location.' />,
              toastOptions
            )
          }
        })
    }
  }

  return (
    <form className='admin-form' onSubmit={handleSubmit}>
      <div className='admin-form-flex w-72'>
        <div className='location-container'>
          <label htmlFor='prefix' className='location-input-label'>
            Prefix
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            disabled={!!loc}
            name='prefix'
            type='text'
            placeholder='Enter Location Prefix'
            value={location?.prefix}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='name' className='location-input-label'>
            Operating Unit
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='name'
            type='text'
            placeholder='Enter Location Name'
            value={location?.name}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='cluster' className='location-input-label'>
            Cluster
            <Asterisk />
            &nbsp;
          </label>
          <select
            name='cluster'
            value={location?.cluster}
            onChange={handleChange}
            className='bg-none'
          >
            <option value='clinic'>Godavari</option>
          </select>
        </div>
        <div className='location-container'>
          <label htmlFor='type' className='location-input-label'>
            Type
            <Asterisk />
            &nbsp;:
          </label>
          <select
            name='type'
            value={location?.type}
            onChange={handleChange}
            className='bg-none'
          >
            <option value='clinic'>Clinic</option>
            <option value='hospital'>Hospital</option>
            <option value='ae'>A&E</option>
          </select>
        </div>
        <div className='location-container'>
          <label htmlFor='ipPrefix' className='location-input-label'>
            Code
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            disabled={!!loc}
            name='ipPrefix'
            type='text'
            placeholder='Enter Code'
            value={location?.ipPrefix}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='address' className='location-input-label'>
            Address
            <Asterisk />
            &nbsp;:
          </label>
          <textarea
            required
            name='address'
            rows={4}
            placeholder='Enter Address'
            value={location?.address}
            onChange={handleChange}
            className='location-input-content'
          ></textarea>
        </div>
        <div className='location-container'>
          <label htmlFor='phone' className='location-input-label'>
            Phone Number
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='phone'
            type='text'
            placeholder='Enter Phone Number'
            value={location?.phone}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='email' className='location-input-label'>
            Email
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='email'
            type='text'
            placeholder='Enter Email'
            value={location?.email}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='gst' className='location-input-label'>
            GST No
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='gst'
            type='text'
            placeholder='Enter GST No'
            value={location?.gst}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='dlNumber' className='location-input-label'>
            DL No
            <Asterisk />
            &nbsp;:
          </label>
          <input
            required
            name='dlNumber'
            type='text'
            placeholder='Enter DL No'
            value={location?.dlNumber}
            onChange={handleChange}
            className='location-input-content'
          />
        </div>
        <div className='location-container'>
          <label htmlFor='hasInventory' className='location-input-label'>
            Inventory:
          </label>
          <input
            type='checkbox'
            name='hasInventory'
            checked={location?.hasInventory === 'yes'}
            onChange={handleChange}
            className='location-input-content'
            style={{ height: '20px' }}
          />
        </div>

        <div className='location-container'>
          <div className='location-input-label'></div>
          <div className='location-input-content'>
            <Box className='box-btn-location'>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className='submit-btn-location'
              >
                Submit
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </form>
  )
}

export default LocationForm
