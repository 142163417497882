import { useState } from 'react'
import BedManagement from './BedManagement'
import BedAllocation from '../allocation/BedAllocation'
import '../bms.scss'
import { allocation } from '../modals'

const BedOverview = () => {
  const [route, setRoute] = useState('bedManagement')
  const handleSelectChange = (event: any) => {
    setRoute(event.target.value)
  }

  return (
    <>
      <div>
        <div className='flex-container'>
          <div className='membershipText'>{allocation?.bedview}</div>
          <div>
            <select
              className={`w105 ${
                route === 'bedManagement' ? 'bedallocate' : 'bedallocate'
              }`}
              name='location'
              value={route}
              onChange={handleSelectChange}
            >
              <option value='bedManagement'>List View</option>
              <option value='bedLayout'>Layout View</option>
            </select>
          </div>
        </div>
        <div>
          {route === allocation.bedManagementRoute ? (
            <BedManagement />
          ) : (
            <BedAllocation />
          )}
        </div>
      </div>
    </>
  )
}

export default BedOverview
