/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import LabTests from '../../../labTests/LabTests'
import { toast } from 'react-toastify'
import ToastMessage from '../ToastMessage'
import { Package, toastOptions } from '../../models'
import { LabOrder, LabTest, Panel } from '../../../labTests/models'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'
import KeycloakService from '../../../../utils/keycloakService'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { selectedLmsItemId, setLmsFormMode } from '../../lmsSlice'
import { PACKAGES_WRITE } from '../../../../utils/roles'

type PackageItem = {
  name?: string
  price?: string
  from?: string
  till?: string
}

const Packages = () => {
  const packageId = useAppSelector(selectedLmsItemId)
  const dispatch = useAppDispatch()
  const [item, setItem] = useState<PackageItem>({})
  const [order, setOrder] = useState<LabOrder>({})

  useEffect(() => {
    if (packageId) {
      const intent = 'getPackageById'
      const state = { id: packageId }
      startSxpProxy(LABS_PROJECT_ID, intent, state)
        .then((data) => {
          const details = data.data.packages_by_pk
          const pkg: PackageItem = {
            name: details.name,
            price: details.price,
            from: details?.active_from?.slice(0, 10),
            till: details?.active_till?.slice(0, 10),
          }
          setItem(pkg)
          setOrder({ panels: details.panels, lab_tests: details.lab_tests })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [packageId])

  const handleSave = (panels?: Panel[], labTests?: LabTest[]) => {
    if (!KeycloakService.hasRole([PACKAGES_WRITE])) {
      return
    }
    if (!item.name) {
      toast(<ToastMessage message={'Enter a valid Name'} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    if (!item.price) {
      toast(<ToastMessage message={'Enter a valid Price'} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    if (!item.from) {
      toast(<ToastMessage message={'Enter a valid From Date'} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    if (!item.till) {
      toast(<ToastMessage message={'Enter a valid To Date'} />, {
        ...toastOptions,
        type: 'error',
      })
      return
    }
    const data: Partial<Package> = {
      name: item.name,
      price: parseInt(item.price),
      active_from: item.from,
      active_till: item.till,
      panels: panels,
      lab_tests: labTests,
      updated_by: KeycloakService.getFullname(),
    }
    if (!packageId) {
      data.created_by = KeycloakService.getFullname()
    }
    const intent = packageId ? 'updatePackageById' : 'createPackage'
    const state: any = {
      package: data,
    }
    if (packageId) {
      state.id = packageId
    }
    startSxpProxy(LABS_PROJECT_ID, intent, state)
      .then(() => {
        dispatch(setLmsFormMode('none'))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <div className='packages-block'>
        <div>
          <label>Name</label>
          <input
            type='text'
            value={item.name ?? ''}
            onChange={(e) =>
              setItem((itm) => ({ ...itm, name: e.target.value }))
            }
          />
        </div>
        <div>
          <label>Price</label>
          <input
            type='text'
            value={item.price ?? 0}
            onChange={(e) =>
              setItem((itm) => ({ ...itm, price: e.target.value }))
            }
          />
        </div>
        <div>
          <label>Active From</label>
          <input
            type='date'
            value={item.from ?? ''}
            onChange={(e) =>
              setItem((itm) => ({ ...itm, from: e.target.value }))
            }
          />
        </div>
        <div>
          <label>Active Till</label>
          <input
            type='date'
            value={item.till ?? ''}
            onChange={(e) =>
              setItem((itm) => ({ ...itm, till: e.target.value }))
            }
          />
        </div>
      </div>
      <LabTests
        mode={'package'}
        order={order}
        id={packageId}
        patientId={''}
        onSave={handleSave}
      />
    </>
  )
}

export default Packages
