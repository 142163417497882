import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import DoctorsForm, { FormDoctor } from './DoctorsForm'
import { PractitionerRole } from 'fhir/r4'

export const formatDoctor = (e: any): FormDoctor => {
  const practitionerRole: PractitionerRole = e.find(
    (e: { resource: { resourceType: string } }) =>
      e?.resource?.resourceType === 'PractitionerRole'
  )?.resource
  const code = practitionerRole.specialty?.[0]?.coding?.[0]?.code ?? ''
  const display = practitionerRole.specialty?.[0]?.coding?.[0]?.display ?? ''
  const practitioner = e.find(
    (e: { resource: { resourceType: string } }) =>
      e?.resource?.resourceType === 'Practitioner'
  )
  const id = practitioner?.resource.id
  const gender = practitioner?.resource?.gender
  const salutation = practitioner?.resource?.name?.[0]?.prefix?.[0]
  const firstName = practitioner?.resource?.name?.[0]?.given?.[0] ?? '-'
  const middleName = practitioner?.resource?.name?.[0]?.given?.[0] ?? '-'
  const lastName = practitioner?.resource?.name?.[0]?.family ?? '-'
  const email =
    practitioner?.resource?.telecom?.find(
      (e: { system: string }) => e?.system === 'email'
    )?.value ?? ''
  const phone =
    practitioner?.resource?.telecom?.find(
      (e: { system: string }) => e?.system === 'phone'
    )?.value ?? '-'
  const qualification = practitioner?.resource?.qualification?.[0]?.code?.text
  const regNumber =
    practitioner?.resource?.qualification?.[0]?.identifier?.[0]?.value ?? '-'
  const active = practitioner?.resource?.active ?? true
  const dob = practitioner?.resource?.birthDate
  const photo = practitioner?.resource?.photo?.[0]?.url
  const signature = practitioner?.resource?.photo?.[1]?.url
  const speciality = `${display}::${code}`
  const healthService =
    practitionerRole?.healthcareService?.map?.(
      (hs) => hs.reference?.split?.('/')?.[1] ?? ''
    ) ?? []
  return {
    id,
    gender,
    active,
    email,
    phone,
    qualification,
    regNumber,
    salutation,
    firstName,
    lastName,
    middleName,
    dob,
    speciality,
    healthService,
    photo,
    signature,
  }
}

const EditDoctors = () => {
  const [doctor, setDoctor] = useState<FormDoctor | null>(null)
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      startSxpProxy(ADMIN_PROJECT_ID, 'getDoctorById', { id })
        .then((data) => {
          setDoctor(formatDoctor(data?.data?.entry))
        })
        .catch((err) => console.log(err))
    }
  }, [id])

  const navigate = useNavigate()
  return (
    <>
      <button
        className='base-button back-button'
        onClick={() => navigate('/administration/doctors')}
      >
        Back to Doctors
      </button>
      {doctor ? (
        <DoctorsForm doctor={doctor} isEdit={true} />
      ) : (
        <p>Not a Valid Doctor Id</p>
      )}
    </>
  )
}

export default EditDoctors
