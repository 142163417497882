import React, { MouseEventHandler, useState } from 'react'
import { styled } from '@mui/system'

const Wrapper = styled('div')(() => ({
  width: '207px',
  position: 'relative',
  paddingTop: '20px',
  height: '500px',
  display: 'block',
  margin: '40px auto',
  '& svg:hover': {
    cursor: 'pointer',
  },
  '& svg:hover path': {
    fill: '#d68a80',
  },
  '& svg.selected path': {
    fill: '#d68a80',
  },
  '& svg': {
    position: 'absolute',
    left: '50%',
    fill: '#a3b2b3',
  },
  '& svg#head': {
    marginLeft: '-28.5px',
    top: '-17px',
  },
  '& svg#neck': {
    marginLeft: '-44.5px',
    top: '28px',
  },
  '& svg#leftShoulder': {
    marginLeft: '-53.5px',
    top: '69px',
  },
  '& svg#rightShoulder': {
    marginLeft: '13.5px',
    top: '69px',
  },
  '& svg#leftArm': {
    marginLeft: '-78px',
    top: '112px',
  },
  '& svg#rightArm': {
    marginLeft: '38px',
    top: '112px',
    zIndex: 10001,
  },
  '& svg#chest': {
    marginLeft: '-43.5px',
    top: '88px',
  },
  '& svg#stomach': {
    marginLeft: '-37.5px',
    top: '130px',
  },
  '& svg#leftLeg': {
    marginLeft: '-46.5px',
    top: '205px',
    zIndex: 9999,
  },
  '& svg#rightLeg': {
    marginLeft: '1.5px',
    top: '205px',
    zIndex: 9999,
  },
  '& svg#leftHand': {
    marginLeft: '-102.5px',
    top: '224px',
  },
  '& svg#rightHand': {
    marginLeft: '66.5px',
    top: '224px',
    zIndex: 10000,
  },
  '& svg#leftFoot': {
    marginLeft: '-35.5px',
    top: '455px',
  },
  '& svg#rightFoot': {
    marginLeft: '5.5px',
    top: '455px',
  },
}))

export interface PartsGroups {
  head: { head: boolean }
  trunk: {
    neck: boolean
    leftShoulder: boolean
    rightShoulder: boolean
    leftArm: boolean
    rightArm: boolean
    chest: boolean
    stomach: boolean
  }
  legs: { leftLeg: boolean; rightLeg: boolean }
  hands: { rightHand: boolean; leftHand: boolean }
  foots: { leftFoot: boolean; rightFoot: boolean }
}

export interface PartsInput {
  head: PartSelect
  neck: PartSelect
  leftShoulder: PartSelect
  rightShoulder: PartSelect
  leftArm: PartSelect
  rightArm: PartSelect
  chest: PartSelect
  stomach: PartSelect
  leftLeg: PartSelect
  rightLeg: PartSelect
  rightHand: PartSelect
  leftHand: PartSelect
  leftFoot: PartSelect
  rightFoot: PartSelect
}

export interface PartsModel {
  head?: JSX.Element
  neck?: JSX.Element
  leftShoulder?: JSX.Element
  rightShoulder?: JSX.Element
  leftArm?: JSX.Element
  rightArm?: JSX.Element
  chest?: JSX.Element
  stomach?: JSX.Element
  leftLeg?: JSX.Element
  rightLeg?: JSX.Element
  rightHand?: JSX.Element
  leftHand?: JSX.Element
  leftFoot?: JSX.Element
  rightFoot?: JSX.Element
}

export interface BodyComponentProps {
  onClick?: any
  onChange?: any
  partsInput?: PartsInput
  bodyModel?: string
}

export interface PartSelect {
  selected?: boolean
  show?: boolean
}

const FemaleBodyModel: PartsModel = {
  head: (
    // <path d='M 49.432 37.702 L 51.958 20.638 L 28.531 5.896 L 4.949 21.448 L 7.485 38.074 L 4.85 38.591 L 6.312 49.381 L 11.52 51.947 L 13.88 63.577 L 23.6 74.595 L 34.456 74.271 L 44.016 63.901 L 45.934 51.949 L 50.99 50.592 L 52.181 39.262 L 49.432 37.702 Z'></path>
    <path d='M47.01 50.6 L47.11 40.01 L28.09 26.77 L10.16 37.48 L10.13 50.11 L7.22 50.31 L8.54 59.05 L13.22 60.68 L15.34 71.13 L24.07 81.03 L33.83 80.74 L42.42 71.42 L44.14 60.68 L48.82 59.06 L50.03 50.5 L47.01 50.6 Z M8.3 48.94 L8.06 36.52 L28.23 24.69 L49.45 38.85 L49.26 48.17 L53.73 32.82 L41.11 16.13 L25.77 14 L12.57 17.3 L2.87 35.94 L8.3 48.94 Z' />
  ),
  neck: (
    <path d='M16.12 35.59 L24.03 46.87 L27.6 59.43 L19.28 52.8 L15.13 38.56 L16.12 35.59 Z M40.48 35.59 L32.57 46.87 L29 59.44 L37.31 52.81 L41.46 38.56 L40.48 35.59 Z' />
  ),
}

const MaleBodyModel: PartsModel = {
  head: (
    // <path d='M 49.432 37.702 L 51.958 20.638 L 28.531 5.896 L 4.949 21.448 L 7.485 38.074 L 4.85 38.591 L 6.312 49.381 L 11.52 51.947 L 13.88 63.577 L 23.6 74.595 L 34.456 74.271 L 44.016 63.901 L 45.934 51.949 L 50.99 50.592 L 52.181 39.262 L 49.432 37.702 Z'></path>
    <path d='M47.01 50.6 L47.11 40.01 L28.09 26.77 L10.16 37.48 L10.13 50.11 L7.22 50.31 L8.54 59.05 L13.22 60.68 L15.34 71.13 L24.07 81.03 L33.83 80.74 L42.42 71.42 L44.14 60.68 L48.82 59.06 L50.03 50.5 L47.01 50.6 Z M8.3 48.94 L8.06 36.52 L28.23 24.69 L49.45 38.85 L49.26 48.17 L53.73 32.82 L41.11 16.13 L25.77 14 L12.57 17.3 L2.87 35.94 L8.3 48.94 Z' />
  ),
  neck: (
    <path d='M16.12 35.59 L24.03 46.87 L27.6 59.43 L19.28 52.8 L15.13 38.56 L16.12 35.59 Z M40.48 35.59 L32.57 46.87 L29 59.44 L37.31 52.81 L41.46 38.56 L40.48 35.59 Z' />
  ),
  leftShoulder: (
    // <path d='M 38.244 -0.004 L 40.224 9.228 L 28.571 12.085 L 18.502 30.932 L 8.575 36.462 L 0.132 46.755 L 0.219 22.23 L 12.835 8.29 L 19.606 9.571 L 38.244 -0.004 Z'></path>
    <path d='m 38.244,-0.004 1.98,9.232 -11.653,2.857 -7.474,-2.637 z M 17.005,10.536 12.962,8.35 0.306,22.35 0.244,27.675 c 0,0 16.52,-17.015 16.764,-17.14 z m 1.285,0.58 C 18.3,11.396 0.528,30.038 0.528,30.038 L -0.01,46.595 6.147,36.045 18.017,30.989 26.374,15.6 Z' />
  ),
  rightShoulder: (
    // <path d='M 3.276 -0.004 L 1.296 9.228 L 11.877 12.475 L 22.083 31.131 L 34.053 36.518 L 42.201 47.802 L 41.18 22.506 L 28.53 8.516 L 19.394 8.766 L 3.276 -0.004 Z'></path>
    <path d='m 3.2759972,-0.004 -1.98,9.232 11.6529998,2.857 7.473999,-2.637 z m 21.2379988,10.54 4.044,-2.187 12.656,14 0.07,5.33 c 0,0 -16.524,-17.019 -16.769,-17.144 z m -1.285,0.58 c -0.008,0.28 17.762,18.922 17.762,18.922 l 0.537,16.557 -6.157,-10.55 -11.871,-5.057 L 15.147997,15.6 Z' />
  ),
  leftArm: (
    // <path d='M 17.229 50.062 L 18.682 43.737 L 16.442 16.358 L 33.128 0.155 L 39.953 9.973 L 29.237 79.632 L 10.364 119.167 L -0.977 115.813 L 6.654 77.532 L 17.229 50.062 Z'></path>
    <path d='m21.12,56.5a1.678,1.678 0 0 1 -0.427,0.33l0.935,8.224l12.977,-13.89l1.2,-8.958a168.2,168.2 0 0 0 -14.685,14.294zm1.387,12.522l-18.07,48.91l5.757,1.333l19.125,-39.44l3.518,-22.047l-10.33,11.244zm-5.278,-18.96l2.638,18.74l-17.2,46.023l-2.657,-1.775l6.644,-35.518l10.575,-27.47zm18.805,-12.323a1.78,1.78 0 0 1 0.407,-0.24l3.666,-27.345l-7.037,-10.139l-7.258,10.58l-6.16,37.04l0.566,4.973a151.447,151.447 0 0 1 15.808,-14.87l0.008,0.001zm-13.742,-28.906l-3.3,35.276l-2.2,-26.238l5.5,-9.038z' />
  ),
  rightArm: (
    // <path d='M 6.186 57.362 L 0.01 10.154 L 7.047 0.015 L 18.284 8.364 L 22.997 18.252 L 21.226 43.483 L 33.91 77.03 L 40.918 112.541 L 35.761 118.994 L 29.43 118.465 L 11.079 79.559 L 6.186 57.362 Z'></path>
    <path d='m 18.997,56.5 a 1.678,1.678 0 0 0 0.427,0.33 L 18.489,65.054 5.512,51.164 4.312,42.206 A 168.2,168.2 0 0 1 18.997,56.5 Z m -1.387,12.522 18.07,48.91 -5.757,1.333 L 10.798,79.825 7.28,57.778 17.61,69.022 Z m 5.278,-18.96 -2.638,18.74 17.2,46.023 2.657,-1.775 L 33.463,77.532 22.888,50.062 Z M 4.083,37.739 A 1.78,1.78 0 0 0 3.676,37.499 L 0.01,10.154 7.047,0.015 l 7.258,10.58 6.16,37.04 -0.566,4.973 A 151.447,151.447 0 0 0 4.091,37.738 l -0.008,10e-4 z m 13.742,-28.906 3.3,35.276 2.2,-26.238 -5.5,-9.038 z' />
  ),
  chest: (
    // <path d='M 19.32 0 L 9.58 14.601 L -0.005 21.544 L 6.145 26.38 L 10.977 40.45 L 22.177 45.066 L 38.91 36.349 L 46.11 36.037 L 64.211 45.157 L 75.21 40.048 L 79.956 26.138 L 87.048 21.573 L 76.817 14.103 L 66.985 0.152 L 51.079 1.833 L 48.807 5.171 L 36.261 5.483 L 34.051 1.394 L 19.32 0 Z'></path>
    <path d='M19.32 0l-9.225 16.488-10.1 5.056 6.15 4.836 4.832 14.07 11.2 4.616 17.85-8.828-4.452-34.7zm47.934 0l9.225 16.488 10.1 5.056-6.15 4.836-4.833 14.07-11.2 4.616-17.844-8.828 4.45-34.7z' />
  ),
  stomach: (
    // <path d='M 15.988 6.215 L 0.765 1.373 L 8.471 30.123 L 6.866 55.306 L 0.057 67.982 L 10.522 82.302 L 36.246 107.323 L 38.8 107.227 L 65.182 83.078 L 75.754 68.424 L 68.905 55.361 L 66.776 30.912 L 74.336 2.311 L 55.921 6.748 L 39.102 0.128 L 34.984 0.264 L 15.988 6.215 Z'></path>
    <path d='M19.25 7.49l16.6-7.5-.5 12.16-14.943 7.662zm-10.322 8.9l6.9 3.848-.8-9.116zm5.617-8.732L1.32 2.15 6.3 15.6zm-8.17 9.267l9.015 5.514 1.54 11.028-8.795-5.735zm15.53 5.89l.332 8.662 12.286-2.665.664-11.826zm14.61 84.783L33.28 76.062l-.08-20.53-11.654-5.736-1.32 37.5zM22.735 35.64L22.57 46.3l11.787 3.166.166-16.657zm-14.16-5.255L16.49 35.9l1.1 11.25-8.8-7.06zm8.79 22.74l-9.673-7.28-.84 9.78L-.006 68.29l10.564 14.594 5.5.883 1.98-20.735zM56 7.488l-16.6-7.5.5 12.16 14.942 7.66zm10.32 8.9l-6.9 3.847.8-9.116zm-5.617-8.733L73.93 2.148l-4.98 13.447zm8.17 9.267l-9.015 5.514-1.54 11.03 8.8-5.736zm-15.53 5.89l-.332 8.662-12.285-2.665-.664-11.827zm-14.61 84.783l3.234-31.536.082-20.532 11.65-5.735 1.32 37.5zm13.78-71.957l.166 10.66-11.786 3.168-.166-16.657zm14.16-5.256l-7.915 5.514-1.1 11.25 8.794-7.06zm-8.79 22.743l9.673-7.28.84 9.78 6.862 12.66-10.564 14.597-5.5.883-1.975-20.74z' />
  ),
  leftLeg: (
    // <path d='M 34.822 170.168 L 35.794 164.644 L 36.888 158.794 L 39.264 152.9 L 34.561 129.077 L 39.58 87.961 L 43.599 36.561 L 10.799 0.928 L 0.232 30.113 L 5.641 63.554 L 4.668 89.142 L 11.542 121.956 L 10.806 159.345 L 9.017 195.132 L 16.544 224.793 L 22.674 252.725 L 30.692 253.507 L 33.937 215.649 L 38.807 201.895 L 39.47 186.808 L 34.822 170.168 Z'></path>
    <path d='m 18.00179,139.99461 -0.664,5.99 4.647,5.77 1.55,9.1 3.1,1.33 2.655,-13.755 1.77,-4.88 -1.55,-3.107 z m 20.582,0.444 -3.32,9.318 -7.082,13.755 1.77,12.647 5.09,-14.2 4.205,-7.982 z m -26.557,-12.645 5.09,27.29 -3.32,-1.777 -2.656,8.875 z m 22.795,42.374 -1.55,4.88 -3.32,20.634 -0.442,27.51 4.65,26.847 -0.223,-34.39 4.87,-13.754 0.663,-15.087 z m -10.623,12.424 1.106,41.267 c 14.157565,64.57987 -5.846437,10.46082 -16.8199998,-29.07 l 5.5329998,-36.384 z m -9.71,-178.164003 0,22.476 15.71,31.073 9.923,30.850003 -1.033,-21.375 z m 25.49,30.248 0.118,-0.148 -0.793,-2.024 -16.545,-18.16 -1.242,-0.44 10.984,28.378 z m -6.255,10.766 6.812,17.6 2.274,-21.596 -1.344,-3.43 z m -26.4699998,17.82 0.827,25.340003 12.8159998,35.257 -3.928,10.136 -12.6099998,-44.51 z M 31.81879,76.04161 l 0.345,0.826 6.47,15.48 -4.177,38.342 -6.594,-3.526 5.715,-35.7 z m -21.465,-74.697003 0.827,21.373 L 4.1527902,65.02561 0.84679017,30.870607 Z m 2.068,27.323 14.677,32.391 3.307,26.000003 -6.2,36.58 -13.437,-37.241 -0.8269998,-38.342003 z' />
  ),
  rightLeg: (
    // <path d='M 34.308 1.138 L 1.595 32.897 L 4.199 87.843 L 8.051 128.404 L 5.559 134.166 L 4.78 153.519 L 9.418 161.961 L 8.838 170.375 L 5.001 186.538 L 5.695 201.551 L 10.359 215.894 L 9.982 257.679 L 21.301 252.703 L 36.543 194.712 L 32.595 162.705 L 32.401 129.906 L 30.401 125.278 L 40.239 89.299 L 40.455 61.267 L 43.818 30.666 L 34.308 1.138 Z'></path>
    <path d='m 26.664979,139.7913 0.663,5.99 -4.647,5.77 -1.55,9.1 -3.1,1.33 -2.655,-13.755 -1.77,-4.88 1.55,-3.107 z m -20.5820002,0.444 3.3200005,9.318 7.0799997,13.755 -1.77,12.647 -5.0899997,-14.2 -4.2000005,-7.987 z m 3.7620005,29.73 1.5499997,4.88 3.32,20.633 0.442,27.51 -4.648,26.847 0.22,-34.39 -4.8670002,-13.754 -0.67,-15.087 z m 10.6229997,12.424 -1.107,41.267 -8.852,33.28 9.627,-4.55 16.046,-57.8 -5.533,-36.384 z m -13.9460002,74.991 c -5.157661,19.45233 -2.5788305,9.72616 0,0 z M 30.177979,4.225305 l 0,22.476 -15.713,31.072 -9.9230002,30.850005 1.033,-21.375005 z m -25.4930002,30.249 -0.118,-0.15 0.793,-2.023 16.5450002,-18.16 1.24,-0.44 -10.98,28.377 z m 6.2550002,10.764 -6.8120002,17.6 -2.274,-21.595 1.344,-3.43 z m 26.47,17.82 -0.827,25.342005 -12.816,35.25599 3.927,10.136 12.61,-44.50999 z m -24.565,12.783005 -0.346,0.825 -6.4700002,15.48 4.1780002,38.34199 6.594,-3.527 -5.715,-35.69999 z m 19.792,51.74999 -5.09,27.29 3.32,-1.776 2.655,8.875 z m 1.671,-126.452995 -0.826,21.375 7.03,42.308 3.306,-34.155 z m -2.066,27.325 -14.677,32.392 -3.308,26.000005 6.2,36.57999 13.436,-37.23999 0.827,-38.340005 z' />
  ),
  rightHand: (
    <path d='M 15.281 0.317 L 9.85 6.26 L 1.651 8.339 L 1.305 19.734 L 6.477 37.003 L 9.036 36.995 L 7.405 26.637 L 8.8 26.553 C 8.8 26.553 14.545 38.621 14.221 38.621 L 16.914 38.069 L 13.896 25.545 L 14.948 25.174 L 22.308 38.398 L 25.673 37.74 L 21.074 24.172 L 21.898 23.56 L 31.127 35.891 L 33.934 33.745 L 23.755 11.12 L 33.214 16.208 L 35.792 12.06 L 27.263 4.38 L 15.281 0.317 Z'></path>
  ),
  leftHand: (
    <path d='M 21.893 1.486 L 27.006 7.43 L 34.992 8.871 L 32.786 21.329 L 28.465 37.109 L 25.906 37.527 L 25.942 26.637 L 24.121 26.34 L 20.721 38.408 L 17.921 37.644 L 19.769 25.545 L 18.824 25.174 L 12.102 37.76 L 9.056 36.89 L 13.974 23.747 L 13.575 23.347 L 4.346 34.19 L 1.008 32.363 L 12.081 12.581 L 11.506 11.545 L 0.665 14.72 L -1.914 10.998 L 21.893 1.486 Z'></path>
  ),
  leftFoot: (
    <path d='M 2.167 22.595 L 14.491 2.905 L 22.295 3.398 L 26.954 7.665 L 23.162 33.553 L 18.986 33.71 L 17.194 25.729 L 16.559 31.003 L 15.009 31.095 L 13.441 25.263 L 12.93 30.591 L 10.683 29.829 L 9.88 24.825 L 9.052 29.4 L 6.436 29.455 L 6.018 24.163 L 5.097 29.251 L 2.073 28.438 L 2.167 22.595 Z'></path>
  ),
  rightFoot: (
    <path d='M 6.378 3.485 L 6.18 26.763 L 7.958 33.198 L 11.794 33.082 L 11.963 27.717 L 13.86 32.134 L 15.962 31.932 L 15.795 27.255 L 18.39 31.123 L 20.696 30.607 L 19.257 26.201 L 23.069 29.834 L 24.706 29.107 L 23.997 24.581 L 26.322 27.261 L 27.578 25.159 L 20.436 6.313 L 13.535 1.527 L 6.378 3.485 Z'></path>
  ),
}

const BodyComponent: React.FC<BodyComponentProps> = ({
  onClick,
  onChange,
  partsInput,
  bodyModel = 'male',
}) => {
  let currentBodyModel = MaleBodyModel
  switch (bodyModel) {
    case 'female':
      currentBodyModel = {
        ...currentBodyModel,
        ...FemaleBodyModel,
      }
      break
  }

  const partsInputInitial = {
    head: { selected: false },
    neck: { selected: false },
    leftShoulder: { selected: false },
    rightShoulder: { selected: false },
    leftArm: { selected: false },
    rightArm: { selected: false },
    chest: { selected: false },
    stomach: { selected: false },
    leftLeg: { selected: false },
    rightLeg: { selected: false },
    rightHand: { selected: false },
    leftHand: { selected: false },
    leftFoot: { selected: false },
    rightFoot: { selected: false },
  }

  partsInput = {
    ...partsInputInitial,
    ...partsInput,
  }

  const [parts, setParts] = useState<PartsInput>(partsInput)

  const setValue = function (value: boolean | PartSelect): PartSelect {
    if (value === true || value === false) {
      value = { selected: !value }
    } else if (value) {
      value.selected = !value.selected
    } else {
      value = { selected: true }
    }
    return value
  }

  const onClickSvg: MouseEventHandler = (event): void => {
    if (!event.target || !(event.target instanceof Element)) {
      return
    }
    const target: Element = event.target
    const id = target.id || target.parentElement?.id
    if (!id) {
      return
    }

    let property: keyof typeof parts
    for (property in parts) {
      if (
        id !== property ||
        (parts[property] && parts[property].show === false)
      ) {
        continue
      }
      parts[property] = setValue(parts[property])
    }

    const partsChanged = { ...parts }
    setParts(partsChanged)
    if (onChange) onChange(partsChanged)
    if (onClick) onClick(id)
  }

  const svgElements = {
    render: (): Array<JSX.Element> => {
      let property: keyof typeof parts
      const elements: Array<JSX.Element> = []
      for (property in parts) {
        if (parts[property] && parts[property].show === false) {
          continue
        }
        const svg = svgElements[property]
        const selected: boolean = parts[property].selected ? true : false
        elements.push(svg(selected))
      }
      return elements
    },
    head: function head(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='head'
          key='head'
          id='head'
          className={(selected ? 'selected ' : '') + 'head'}
          xmlns='http://www.w3.org/2000/svg'
          width='56.594'
          height='90.031'
          viewBox='0 0 56.594 95.031'
        >
          {currentBodyModel.head}
        </svg>
      )
    },
    neck: function neck(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='neck'
          key='neck'
          id='neck'
          className={(selected ? 'selected ' : '') + 'neck'}
          xmlns='http://www.w3.org/2000/svg'
          width='90.594'
          height='80.031'
          viewBox='0 0 56.594 95.031'
        >
          {currentBodyModel.neck}
        </svg>
      )
    },
    leftShoulder: function leftShoulder(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='leftShoulder'
          key='leftShoulder'
          id='leftShoulder'
          className={(selected ? 'selected ' : '') + 'leftShoulder'}
          xmlns='http://www.w3.org/2000/svg'
          width='109.532'
          height='46.594'
          viewBox='0 0 109.532 46.594'
        >
          {currentBodyModel.leftShoulder}
        </svg>
      )
    },
    rightShoulder: function rightShoulder(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='rightShoulder'
          key='rightShoulder'
          id='rightShoulder'
          className={(selected ? 'selected ' : '') + 'rightShoulder'}
          xmlns='http://www.w3.org/2000/svg'
          width='109.532'
          height='46.594'
          viewBox='0 0 109.532 46.594'
        >
          {currentBodyModel.rightShoulder}
        </svg>
      )
    },
    leftArm: function leftArm(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='leftArm'
          key='leftArm'
          id='leftArm'
          className={(selected ? 'selected ' : '') + 'leftArm'}
          xmlns='http://www.w3.org/2000/svg'
          width='156.344'
          height='119.25'
          viewBox='0 0 156.344 119.25'
        >
          {currentBodyModel.leftArm}
        </svg>
      )
    },
    rightArm: function rightArm(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='rightArm'
          key='rightArm'
          id='rightArm'
          className={(selected ? 'selected ' : '') + 'rightArm'}
          xmlns='http://www.w3.org/2000/svg'
          width='156.344'
          height='119.25'
          viewBox='0 0 156.344 119.25'
        >
          {currentBodyModel.rightArm}
        </svg>
      )
    },
    chest: function chest(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='chest'
          key='chest'
          id='chest'
          className={(selected ? 'selected ' : '') + 'chest'}
          xmlns='http://www.w3.org/2000/svg'
          width='86.594'
          height='45.063'
          viewBox='0 0 86.594 45.063'
        >
          {currentBodyModel.chest}
        </svg>
      )
    },
    stomach: function stomach(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='stomach'
          key='stomach'
          id='stomach'
          className={(selected ? 'selected ' : '') + 'stomach'}
          xmlns='http://www.w3.org/2000/svg'
          width='75.25'
          height='107.594'
          viewBox='0 0 75.25 107.594'
        >
          {currentBodyModel.stomach}
        </svg>
      )
    },
    leftLeg: function leftLeg(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='leftLeg'
          key='leftLeg'
          id='leftLeg'
          className={(selected ? 'selected ' : '') + 'leftLeg'}
          xmlns='http://www.w3.org/2000/svg'
          width='93.626'
          height='250.625'
          viewBox='0 0 93.626 250.625'
        >
          {currentBodyModel.leftLeg}
        </svg>
      )
    },
    rightLeg: function rightLeg(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='rightLeg'
          key='rightLeg'
          id='rightLeg'
          className={(selected ? 'selected ' : '') + 'rightLeg'}
          xmlns='http://www.w3.org/2000/svg'
          width='80'
          height='250.625'
          viewBox='0 0 80 250.625'
        >
          {currentBodyModel.rightLeg}
        </svg>
      )
    },
    leftHand: function leftHand(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='leftHand'
          key='leftHand'
          id='leftHand'
          className={(selected ? 'selected ' : '') + 'leftHand'}
          xmlns='http://www.w3.org/2000/svg'
          width='90'
          height='38.938'
          viewBox='0 0 90 38.938'
        >
          {currentBodyModel.leftHand}
        </svg>
      )
    },
    rightHand: function rightHand(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='rightHand'
          key='rightHand'
          id='rightHand'
          className={(selected ? 'selected ' : '') + 'rightHand'}
          xmlns='http://www.w3.org/2000/svg'
          width='90'
          height='38.938'
          viewBox='0 0 90 38.938'
        >
          {currentBodyModel.rightHand}
        </svg>
      )
    },
    leftFoot: function leftFoot(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='leftFoot'
          key='leftFoot'
          id='leftFoot'
          className={(selected ? 'selected ' : '') + 'leftFoot'}
          xmlns='http://www.w3.org/2000/svg'
          width='30'
          height='30'
          viewBox='0 0 30 30'
        >
          {currentBodyModel.leftFoot}
        </svg>
      )
    },
    rightFoot: function rightFoot(selected: boolean): JSX.Element {
      return (
        <svg
          onClick={onClickSvg}
          data-position='rightFoot'
          key='rightFoot'
          id='rightFoot'
          className={(selected ? 'selected ' : '') + 'rightFoot'}
          xmlns='http://www.w3.org/2000/svg'
          width='90'
          height='38.938'
          viewBox='0 0 90 38.938'
        >
          {currentBodyModel.rightFoot}
        </svg>
      )
    },
  }

  return <Wrapper className='human-body'>{svgElements.render()}</Wrapper>
}

export default BodyComponent
