import React, { useState } from 'react'
import './Filter.css'
import { useAppDispatch } from '../../../app/hooks'
import { setPatientStatusActive, fetchPatientsAsync } from '../patientsSlice'
import { Button } from '@mui/material'

const FilterButton = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [statusFilter, setStatusFilter] = useState('true')

  const [isActionOpen, setIsActionOpen] = useState(false)

  const dispatch = useAppDispatch()

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen)
    setIsActionOpen(false)
  }

  const handleStatusFilterChange = (filter: 'active' | 'inactive') => {
    setStatusFilter(filter)
    dispatch(setPatientStatusActive(filter === 'active'))
    dispatch(fetchPatientsAsync())
  }

  const handleDownload = () => {
    console.log('Downloading...')
    setIsActionOpen(false)
  }

  const toggleAction = () => {
    setIsActionOpen(!isActionOpen)
    setIsFilterOpen(false)
  }

  return (
    <div className='filter-dropdown'>
      <Button
        sx={{
          textTransform: 'none',
        }}
        className='filter-button'
        onClick={toggleFilter}
        disableRipple
      >
        Filter
      </Button>
      <Button
        sx={{
          textTransform: 'none',
        }}
        className='filter-button'
        onClick={toggleAction}
      >
        Action
      </Button>
      {isFilterOpen && (
        <div className='dropdown-content'>
          <div
            className='menu2'
            onClick={() => handleStatusFilterChange('active')}
          >
            Active
          </div>
          <div
            className='menu2'
            onClick={() => handleStatusFilterChange('inactive')}
          >
            Inactive
          </div>
        </div>
      )}
      {isActionOpen && (
        <div className='dropdown-content' style={{ marginLeft: '4rem' }}>
          <div className='menu3' onClick={handleDownload}>
            Export
          </div>
        </div>
      )}
    </div>
  )
}

export default FilterButton
