import { useEffect, useState, useRef } from 'react'
import { startSxpProxy } from '../../../utils/api'
import {
  CHAT_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../../utils/constants'
import { getAgeInYears } from '../../../utils/dateUtils'
import { makeName } from '../utils'
import { Patient, Practitioner, Provenance } from 'fhir/r4'
import { getLocationByPrefix, getProvenanceData } from '../../patients/utils'
import { ProvenanceData } from '../../patients/patientsSlice'
import ProvenanceView from '../../../components/ProvenanceView'

type Gender = 'Male' | 'Female' | 'Others'

type SearchPatient = {
  id: string
  name: string
  dob: string
  gender: Gender
  uhid: string
  thopId: string
  mobile: any
  registered_at: any
}

type Props = {
  searchQuery: string
  searchParam: string
  onPatientSelect: (id: string) => void
  setRow?: any
  selectedId: string
  onOrderCreate?: (id: string) => void
}

const PatientTable = ({
  searchQuery,
  searchParam,
  onPatientSelect,
  setRow,
  selectedId,
  onOrderCreate,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [patients, setPatients] = useState<SearchPatient[]>([])

  useEffect(() => {
    setLoading(true)
    const intent = 'getPatientsAPI'
    let q = ''
    switch (searchQuery) {
      case 'ID':
        q = 'identifier'
        break
      case 'Phone':
        q = 'phone'
        break
      case 'Name':
        q = 'name'
        break
    }
    const state = {
      pageSize: 20,
      query: q,
      value: searchParam,
    }
    startSxpProxy(CHAT_PROJECT_ID, intent, state)
      .then((data) => {
        if (data.data.entry?.length) {
          setPatients(formatPatients(data?.data?.entry))
          setRow(formatPatients(data?.data?.entry))
        } else {
          setPatients([])
          setRow([])
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchParam])

  const formatPatients = (entry: { resource: Patient }[]): SearchPatient[] => {
    return (
      entry.map((en) => {
        const gender = en.resource.gender
        const pat: SearchPatient = {
          id: en.resource.id ?? '',
          name: makeName(en.resource.name),
          gender:
            gender === 'male'
              ? 'Male'
              : gender === 'female'
              ? 'Female'
              : 'Others',
          dob: en.resource.birthDate ?? '',
          uhid: en.resource?.identifier?.[2]?.value ?? '',
          thopId: en.resource?.identifier?.[0]?.value ?? '',
          mobile: en.resource?.telecom?.[0]?.value ?? '',
          registered_at: en.resource?.address?.[0]?._city ?? '',
        }
        return pat
      }) ?? []
    )
  }

  const handlePatientSelect = (id: string) => {
    onPatientSelect(id)
  }

  const patientsTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientsTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 25}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const ids = patients.map((p) => p.id).join(',')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  useEffect(() => {
    if (ids) {
      startSxpProxy(REGISTRATION_PROJECT_ID, 'fetchProvenance', {
        patientIds: ids,
      })
        .then((data) => {
          const entries =
            data.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde.resource
            ) ?? []
          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((err) => console.error(err))
    }
  }, [ids])

  return (
    <div className='patient-table' ref={patientsTableContainerRef}>
      <div className='appointments-table-container'>
        <table className='data-table admin-table'>
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              <th>UHID</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Mobile No.</th>
              <th>Operating Unit</th>
              <th>Created</th>
              <th>Updated</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={4} className='text-center'>
                  Loading...
                </td>
              </tr>
            ) : patients.length > 0 ? (
              patients.map((d: SearchPatient) => (
                <tr
                  className={selectedId === d.id ? 'selected-patient' : ''}
                  key={d.id}
                >
                  <td>{d.uhid || d.thopId}</td>
                  <td>
                    <button
                      onClick={() => handlePatientSelect(d.id)}
                      className={
                        selectedId === d.id
                          ? 'selected-patient lms-btn'
                          : 'lms-btn'
                      }
                    >
                      {d.name}
                    </button>
                  </td>
                  <td>{d.gender}</td>
                  <td>{getAgeInYears(d.dob) ?? '-'}</td>
                  <td>{d.mobile}</td>
                  <td>{getLocationByPrefix(d.uhid)}</td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={provenances[d.id]?.createdOn}
                      name={
                        getLocationByPrefix(d.id) === 'Mental Health App'
                          ? 'Self'
                          : provenances[d.id]?.createdBy
                      }
                      mode={'CREATE'}
                    />
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={
                        provenances[d.id]?.updatedOn ??
                        provenances[d.id]?.createdOn
                      }
                      name={
                        getLocationByPrefix(d.id) === 'Mental Health App'
                          ? 'Self'
                          : provenances[d.id]?.updatedBy ??
                            provenances[d.id]?.createdBy
                      }
                      mode={'UPDATE'}
                    />
                  </td>
                  {/* <td>
                    {KeycloakService.hasRole([LAB_ORDERS_WRITE]) && (
                      <button className='ml5' onClick={() => createOrder(d.id)}>
                        Create
                      </button>
                    )}
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className='text-center'>
                  No Patients found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PatientTable
